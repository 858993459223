import gift from "assets/images/Dashboard/d10.png";
import banner2 from "assets/images/Dashboard/d11.png";
import board from "assets/images/Dashboard/d13.png";
import search from "assets/images/Dashboard/d14.png";
import receipt from "assets/images/Dashboard/d15.png";
import banner3 from "assets/images/Dashboard/d16.png";
import smile from "assets/images/Dashboard/d17.png";
import background1 from "assets/images/Dashboard/d4.png";
import audio from "assets/images/Dashboard/d5.png";
import copy from "assets/images/Dashboard/d8.png";
import direction from "assets/images/Dashboard/d9.png";
import Bulb from "assets/images/icons/bulb.png";
import whatsapp from "assets/images/pages/dashboard/whatsapp.png";

import checks from "assets/images/backgrounds/checks.png";
import AudioWaveGif from "assets/images/pages/dashboard/audiowave.gif";
import Pause from "assets/images/pages/dashboard/pause.png";
import Play from "assets/images/pages/dashboard/play.png";
import Cards from "components/Cards";
import LearningCards from "components/Cards/LearningCards";
import CopyReferenceCode from "components/CopyReferenceCode";
import DaysLeft from "components/DaysLeft";
import DoubleCards from "components/DoubleCards";
import GoalMeter from "components/GoalMeter";
import AmountCarousel from "components/Pages/Dashboard/AmountCarousel";
import ExperienceCarousel from "components/Pages/Dashboard/ExperienceCarousel";
import HackCarousel from "components/Pages/Dashboard/HacksCarousel";
import Profile from "components/Profile";
import TopMenu from "components/TopMenu";
import CustomTour from "components/Tour";
import { analytics } from "config/firebase";
import { AuthContext } from "context/Auth";
import { DonationsContext } from "context/Data/Donations";
import NotificationContext from "context/Notification";
import { logEvent } from "firebase/analytics";
import useDocumentTitle from "hooks/useDocumentTitle";
import useLayoutConfig from "hooks/useLayoutConfig";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import OrientationPopup from "components/OrientationPopup";

let note = null;

const Dashboard = () => {
  const { profile } = useContext(AuthContext);

  const { setNotificationMessage } = useContext(NotificationContext);
  const navigate = useNavigate();
  useDocumentTitle(`Muskurahat Foundation | HOME`);

  useLayoutConfig("red", true);

  if (note) {
    note.onended = () => {
      logEvent(analytics, "note_played");
      setIsNotePlaying(false);
    };
  }

  const [isNotePlaying, setIsNotePlaying] = useState(false);

  const cradStyle = {
    backgroundImage: `url(${background1}`,
    width: "150px",
    backgroundSize: "contain",
  };

  const overlayStyle = {
    backgroundPosition: "center center",
    borderRadius: "50px 50px 35px 35px",
    backgroundColor: "#fff",
    backgroundImage: `url(${checks})`,
    paddingBottom: 10,
  };

  const copyDonationLink = () => {
    // copy to clipboard
    navigator.clipboard.writeText(
      `https://muskurahat.org.in/donate?r=${profile.referenceCode}`,
    );
    setNotificationMessage("Donation Link is copied");
  };

  const openWhatsappShareMessage = () => {
    const message = `I+am+Volunteering+with+Muskurahat+Foundation%2C+an+NGO+based+in+Mumbai.+%0D%0A%0D%0AProject+KEYtaab%2C+an+initiative+by+Muskurahat+Foundation+aims+at+providing+quality+education+to+the+underprivileged+children+living+from+orphanages%2C+low+income+communities+and+rural+villages+to+build+their+brighter+future.%0D%0A%0D%0AApart+from+quality+education%2C+we+are+also+building+their+life+skills+like+critical+thinking%2C+communication+and+problem+solving+among+many+others%2C+and+strive+to+improve+their+overall+mental+well-being.+%0D%0A%0D%0ACurrently%2C+we+are+working+with+1500%2B+children+in+10+shelter+homes%2C+3+community+centres+and+5+villages+across+Mumbai+and+Ranchi%0D%0A%0D%0ATo+support+our+children%2C+contribute+at+https%3A%2F%2Fmuskurahat.org.in%2Fdonate%3Fr%3D${profile.referenceCode}%0D%0A%0D%0ATo+know+more%2C+please+visit+www.muskurahat.org.in%0D%0A%0D%0AReferral+Code%3A+${profile.referenceCode}%0D%0A%28Please+keep+in+mind+to+check+the+reference+code+while+donating.+It+will+help+me+track+my+efforts.%29%0D%0A%0D%0AOur+collective+support+can+enable+our+children+to+secure+their+future.%0D%0ALooking+forward+to+your+help%21%E2%9C%A8`;
    window.open(`https://api.whatsapp.com/send?text=${message}`);
  };

  const downloadFile = (link) => {
    window.open(link);
  };

  const donationsContext = useContext(DonationsContext);
  const [isOrientationPopupVisible, setIsOrientationPopupVisible] =
    useState(false);

  useEffect(() => {
    const orientationPopupLastClosed = Date.parse(
      localStorage.getItem("orientation-popup"),
    );

    // if orientation popup was closed more than 3 hours ago, show it again
    // if profile?.orientation?.progress is not available, show the popup
    // if profile?.orientation?.progress is available but length is not 10, show the popup
    // if orientationPopupLastClosed is NaN, show the popup
    if (
      (isNaN(orientationPopupLastClosed) ||
        Date.now() - orientationPopupLastClosed > 3 * 60 * 60 * 1000) &&
      (!profile?.orientation?.progress ||
        (profile?.orientation?.progress &&
          Object.keys(profile.orientation.progress).length !== 10)) &&
      profile?.tour
    ) {
      // Show the orientation popup
      setIsOrientationPopupVisible(true);
    }
  }, []);

  return (
    <>
      {isOrientationPopupVisible && (
        <OrientationPopup
          setIsOrientationPopupVisible={setIsOrientationPopupVisible}
        />
      )}
      <div>
        <div className="flex m-4">
          <div className="w-1/2">
            <DaysLeft />
          </div>
          <div className="w-1/2">
            <div className="flex justify-end gap-4">
              <CopyReferenceCode />
              <Profile />
            </div>
          </div>
        </div>

        <TopMenu />

        <div style={overlayStyle}>
          <div className="text-white text-center py-12">
            <h1 className=" font-bold text-2xl text-secondary">IMPACT</h1>
            <h1 className="font-bold text-2xl text-primary">CALCULATOR</h1>
            <p className="mt-2 text-[10px] text-quaternary">
              See how your goals can create impact
            </p>
          </div>
          <div id="goal-meter">
            <GoalMeter />
          </div>
          <div className="acheive text-center mt-8">
            <div className="achevie-heading">
              <h3 className="text-base font-bold text-secondary">
                {profile?.achieved < 1000 || !profile?.achieved ? (
                  <>
                    Check out the{" "}
                    <Link to="/user/rewards" className="underline">
                      rewards
                    </Link>{" "}
                    section to <span className="text-primary">LEVEL UP!!!</span>
                  </>
                ) : (
                  <>
                    LEVEL ACHIEVED: <br />
                    <span className="text-xl text-center mt-4 font-bold relative text-tertiary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                      {profile?.achieved < 5000
                        ? "STAR! 🌟"
                        : profile?.achieved < 15000
                        ? "NINJA! 🥷🏻"
                        : profile?.achieved < 30000
                        ? "WIZARD! 🧙🏻‍♂️"
                        : "LEGEND! 👑"}
                    </span>
                  </>
                )}
              </h3>
            </div>

            <div className="pt-9 px-5 flex justify-around gap-5" id="">
              <button
                id="start-orientation"
                onClick={() => navigate("/user/orientation")}
                className={`bg-tertiary bg-cover h-16 w-56 mx-auto  mb-6 rounded-2xl flex justify-center items-center`}
              >
                <div className=" text-white font-semibold">
                  <h3>Start Orientation</h3>
                </div>
                <div className="ml-2">
                  <img src={direction} alt="" className="w-12" />
                </div>
              </button>
              <button
                id="get-started"
                onClick={() => navigate("/user/start-here")}
                className={`bg-tertiary bg-cover h-16 w-56 mx-auto  mb-6 rounded-2xl flex justify-center items-center`}
              >
                <div className=" text-white font-semibold">
                  <h3>Start Fundraising</h3>
                </div>
                <div className="ml-2">
                  <img src={direction} alt="" className="w-12" />
                </div>
              </button>
            </div>

            <div className="achevie-card bg-[url('assets/images/backgrounds/purpleButton.png')] bg-cover h-28 w-80 mx-auto mt-12 rounded-2xl flex">
              <div className="mt-[-37px] ml-[-18px]" style={cradStyle}>
                <p className="text-center font-bold w-14 mt-5 ml-20 text-[9px]">
                  Samay this side!
                </p>
              </div>
              <div>
                <div className="pt-3 text-[12px]">
                  <h5 className="text-white capitalize text-right font-bold">
                    A WORD FROM
                  </h5>

                  <h5 className="text-white capitalize text-right font-bold ">
                    YOUR REPORTING MANAGER
                  </h5>
                </div>
                <div className="flex justify-center items-center pt-2 mt-[-5px]">
                  <div>
                    {isNotePlaying ? (
                      <img className="w-[110px]" src={AudioWaveGif} alt="" />
                    ) : (
                      <img className="w-[110px]" src={audio} alt="" />
                    )}
                  </div>
                  <div
                    onClick={() => {
                      if (!note) {
                        note = new Audio(
                          "https://firebasestorage.googleapis.com/v0/b/muskurahat-foundation.appspot.com/o/assets%2Fnote.mp3?alt=media&token=cf2cc637-e67d-4fd7-ae1b-e94455f40087",
                        );
                      }

                      if (isNotePlaying) {
                        note.pause();
                        setIsNotePlaying(false);
                      } else {
                        note.play();
                        setIsNotePlaying(true);
                      }
                    }}
                  >
                    {isNotePlaying ? (
                      <img className="w-14" src={Pause} alt="" />
                    ) : (
                      <img className="w-14" src={Play} alt="" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="flex items-center justify-around p-0 mx-9 mt-9">
            <Cards
              id="donation-link"
              text="Copy Unique Donation Link"
              img={copy}
              onClick={copyDonationLink}
            />
            <Cards
              id="get-started"
              onClick={() => navigate("/user/start-here")}
              text="Begin Internship"
              img={direction}
            />
          </div> */}

          <div className="flex items-center justify-around p-0 mx-9 mt-9">
            <DoubleCards
              button1={{
                id: "donation-link",
                text: "Copy Unique Donation Link",
                img: copy,
                onClick: copyDonationLink,
              }}
              button2={{
                id: "whatsapp-share",
                text: "Share On Whatsapp",
                img: whatsapp,
                onClick: openWhatsappShareMessage,
              }}
            />
          </div>

          <div className="pt-9" id="rewards-button">
            <button
              onClick={() => navigate("/user/rewards")}
              className={`bg-[url('assets/images/backgrounds/redButton.png')] bg-cover h-20 w-80 mx-auto  mb-6 rounded-2xl flex justify-center items-center`}
            >
              <div className=" text-white font-bold ml-4 text-2xl">
                <h3>REWARDS ✨</h3>
              </div>
              <div className="mt-[-34px]">
                <img src={gift} alt="" className="w-32" />
              </div>
            </button>
          </div>

          <div className="relative donation mt-8">
            <div className="w-3/4 mt-5 pb-2 lg:bg-none bg-cover bg-[url('assets/images/pages/hacks/note1.png')]">
              <Link to="/user/donations">
                <h2 className="font-bold pt-5 pb-7 pl-5 text-secondary text-lg text-left">
                  <span className="relative text-secondary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                    RECENT DONATIONS 💰
                  </span>
                </h2>{" "}
              </Link>
            </div>
            {donationsContext?.donations.length > 0 ? (
              <AmountCarousel />
            ) : (
              <div className="flex text-black font-bold text-[10px] justify-between px-5 gap-3 my-5">
                <Link
                  to="/user/hacks/hack-5"
                  className="bg-white shadow-[0px_2px_4px_rgba(0,0,0,0.25)] rounded-lg p-3 flex w-1/2 items-center"
                >
                  <div className="w-3/4">
                    <span className="text-primary">CAPTURING ATTENTION </span>{" "}
                    <br />
                    FOR YOUR CAUSE
                  </div>
                  <div className="w-1/4">
                    <img className="w-[35px] h-[38px]" src={Bulb} />
                  </div>
                </Link>
                <Link
                  to="/user/hacks/hack-3"
                  className="bg-white shadow-[0px_2px_4px_rgba(0,0,0,0.25)] rounded-lg p-3 flex w-1/2  items-center"
                >
                  <div className="w-3/4">
                    BUILDING <span className="text-primary">CONNECTIONS </span>
                    WITH ANYONE
                  </div>
                  <div className="w-1/4">
                    <img className="w-[32px] h-[38px]" src={Bulb} />
                  </div>
                </Link>
              </div>
            )}
            <div className="flex justify-end pt-2 pr-2">
              <Link
                to="/user/donations"
                className="text-primary text-xs text-left underline underline-offset-4 mb-9 cursor-pointer"
              >
                Check all your donations -
              </Link>
            </div>
          </div>

          <div className="experience relative flex justify-end">
            <img
              src={banner3}
              alt="Your Image"
              className="w-[340px] h-[120px]"
            />
            <div className="absolute top-0 left-0 w-full h-full mt-1 ">
              <h2 className="font-bold pt-4 pr-5 text-secondary text-base text-right">
                LEARN FROM YOUR PEERS AND
              </h2>
              <h2 className="font-bold px-1 pr-5 text-base text-right">
                <span className="relative text-primary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                  THEIR EXPERIENCES 🔥
                </span>
              </h2>
            </div>
          </div>
          <ExperienceCarousel />

          <div className="relative credential mt-10">
            <img src={banner2} alt="Your Image" className="w-80 h-auto " />
            <div className="absolute top-0 left-0 w-full h-full flex text-center justify-center">
              <h2 className="font-bold mr-32 pt-4 px-1 text-secondary text-base">
                <span className="relative before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                  OUR CREDENTIALS 📝
                </span>
              </h2>
            </div>
          </div>
          <div className="flex items-center justify-between space-x-4 px-7 py-2.5">
            <Cards
              text="Trust Certificate"
              paddingLeft="pl-1"
              img={board}
              onClick={() => {
                downloadFile(
                  "https://www.muskurahat.org.in/resources/documents/Trust.jpg",
                );
              }}
            />
            <Cards
              text="Audit Report"
              paddingLeft="pl-1"
              img={search}
              onClick={() => {
                downloadFile(
                  "https://www.muskurahat.org.in/resources/documents/AR_FY22-23.pdf",
                );
              }}
            />
            <Cards
              text="80G Certificate"
              paddingLeft="pl-1"
              img={receipt}
              onClick={() => {
                downloadFile(
                  "https://www.muskurahat.org.in/resources/documents/Muskurahat_80G.pdf",
                );
              }}
            />
          </div>

          <div className="mt-10 flex">
            <div className="w-2/5">
              <img src={smile} className="m-auto" alt="" />
            </div>
            <div className="w-3/5 bg-cover bg-[url('assets/images/pages/dashboard/rightnote.png')]">
              <h2 className="font-bold pt-3 pb-7 pr-5 text-secondary text-lg text-right">
                LEARN MORE ABOUT{" "}
                <span className="relative text-primary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                  MUSKURAHAT
                </span>
              </h2>
            </div>
          </div>

          <LearningCards />
        </div>

        <HackCarousel />
      </div>
      <CustomTour />
    </>
  );
};

export default Dashboard;
