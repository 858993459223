import smileIcon from "assets/images/icons/smile.png";
import handshakeIcon from "assets/images/icons/handshake.png";
import mindIcon from "assets/images/icons/mind.png";
import videoThumbnail from "assets/images/pages/orientation/video-thumbnail.png";
import sidebar from "assets/images/Dashboard/c1.png";

const chapters = [
  // 1
  {
    titleComponent: (
      <div className="relative flex lg:justify-between md:justify-between">
        <img
          src={sidebar}
          alt="Your Image"
          className="w-60 h-auto lg:w-[330px] md:w-[18rem]"
        />
        <div className="absolute top-1 left-4 lg:top-4 w-full h-full text-left justify-center">
          <h2 className="font-bold  pt-3 text-secondary text-lg pl-4 lg:text-2xl  lg:pl-[2rem]">
            Welcome To
          </h2>
          <h2 className="font-bold  text-primary text-lg pl-4 lg:text-2xl lg:pl-[2rem] before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/pages/orientation/yellow-underline.png')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 relative inline-block">
            Muskurahat
          </h2>
          <h4 className="text-secondary text-[11px] pl-4 mt-3"></h4>
        </div>
        <div>
          <img
            src={smileIcon}
            alt=""
            className="w-[77px] lg:w-[157px] lg:pr-[30px] md:w-[137px] md:pr-[30px]"
          />
        </div>
      </div>
    ),

    videoId: "1015477498",
    videoTitle: "How crowdfunding enables ideas to become reality!",
    videoThumbnail: videoThumbnail,
    duration: "2 Mins",
    description:
      "This orientation is your first step towards joining Muskurahat in its mission to provide quality education to the children-in-need. Get started, stay disciplined and by the time we reach the end of this orientation let’s hope for a deeper understanding about the cause and how we are going to solve this massive problem.",
    discussionTitle: (
      <h2 className="text-secondary text-center">
        What Do You Know About <span className="text-primary">Muskurahat?</span>
      </h2>
    ),
    discussionPlaceholder:
      "Before we move on,tell us briefly what you know about Muskurahat.",
    nextTitle: "Journey Then & Life Now!",
    nextDescription: "Learn more about Muskurahat’s Journey",
    nextImage: videoThumbnail,
  },
  // 2
  {
    titleComponent: (
      <div className="relative flex lg:justify-between md:justify-between">
        <img
          src={sidebar}
          alt="Your Image"
          className="w-60 h-auto lg:w-[330px] md:w-[18rem]"
        />
        <div className="absolute top-1 left-4 lg:top-4 w-full h-full text-left justify-center">
          <h2 className="font-bold  pt-3 text-secondary text-lg pl-4 lg:text-2xl  lg:pl-[2rem]">
            Journey Then
          </h2>
          <h2 className="font-bold text-primary text-lg pl-7 lg:text-2xl lg:pl-[rem] before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/pages/orientation/yellow-underline.png')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 relative inline-block">
            & Life Now
          </h2>
          <h4 className="text-secondary text-[11px] pl-4 mt-3"></h4>
        </div>
        <div>
          <img
            src={handshakeIcon}
            alt=""
            className="w-[97px] lg:w-[157px] lg:pr-[30px] md:w-[137px] md:pr-[30px]"
          />
        </div>
      </div>
    ),
    duration: "10 Mins",
    videoId: "1015477156",
    videoTitle: "How crowdfunding enables ideas to become reality!",
    videoThumbnail: videoThumbnail,

    description:
      "Ten years of consistently working with our children has brought us closer to understanding their challenges. Our programs have been intricately designed to support their holistic development and make a deeper impact in their lives. Let’s envision a brighter future for our children together.",
    discussionTitle: (
      <h2 className="text-secondary text-center">
        The <span className="text-primary">Manifestation</span> Wall! ✨
      </h2>
    ),
    discussionPlaceholder:
      "Let's pin a comment about the world we envision for our children.",
    nextTitle: "Mental well being of the children",
    nextDescription:
      "Learn More About Muskurahat’s Program for Children-In-Need",
    nextImage: videoThumbnail,
    popupAt: [3, 7],
  },
  // 3
  {
    titleComponent: (
      <div className="relative flex lg:justify-between md:justify-between">
        <img
          src={sidebar}
          alt="Your Image"
          className="w-[310px] h-auto lg:w-[330px] md:w-[18rem]"
        />
        <div className="absolute top-1 left-4 lg:left-2 lg:top-0 w-full h-full text-left justify-center">
          <h2 className="font-bold  pt-3 text-secondary text-lg pl-4 lg:text-2xl  lg:pl-4">
            Mental Well Being
          </h2>
          <h2 className="font-bold text-primary text-lg pl-4 lg:text-2xl lg:pl-4 before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/pages/orientation/yellow-underline.png')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 relative inline-block">
            Of The Children
          </h2>
          <h4 className="text-secondary text-[11px] pl-4 mt-3 leading-1">
            First pillar of Quality Education
            <br /> & Holistic Development!
          </h4>
        </div>
        <div>
          <img
            src={mindIcon}
            alt=""
            className="w-[97px] lg:w-[157px] lg:pr-[40px] md:w-[137px] md:pr-[40px]"
          />
        </div>
      </div>
    ),
    duration: "15 Mins",

    videoId: "1015476878",
    videoTitle: "How crowdfunding enables ideas to become reality!",
    videoThumbnail: videoThumbnail,

    description:
      "Our children are constantly exposed to some of the most discouraging and traumatic experiences in their lives. The need for a conducive environment which fosters a good state of mind, tenacity and sustainable solutions to cope with the challenges of life is too high. Thus, mental wellbeing makes for the first pillar of holistic development.",
    discussionTitle: (
      <h2 className="text-secondary text-center">
        Share your opinions, feelings, doubts or questions here
      </h2>
    ),
    discussionPlaceholder: "",
    nextTitle: "What Is Social Emotional Learning",
    nextDescription: "Learn more about Muskurahat’s Journey",
    nextImage: videoThumbnail,
    popupAt: [3, 7],
    stars: true,
    questions: [
      {
        timestamp: 34,
        question:
          "Will if you pay attention in a classroom if you’re in a really bad mood?",
        options: ["a. Yes", "b. No"],
      },
      {
        timestamp: 34,
        question:
          "Will if you pay attention in a classroom if you’re in a really bad mood?",
        options: ["a. Yes", "b. No"],
      },
      {
        timestamp: 48,
        question: "Will your teacher care about your mood?",
        options: ["a. Yes", "b. No"],
      },
      {
        timestamp: 370,
        question:
          "Is it possible for someone to trust easily after having suffered such trauma in life?",
        options: ["a. Maybe", "b. Highly Unlikely"],
      },
    ],
  },
  // 4
  {
    titleComponent: (
      <div className="relative flex lg:justify-between md:justify-between">
        <img
          src={sidebar}
          alt="Your Image"
          className="w-[280px] h-[180px] lg:w-[330px] md:w-[18rem]"
        />
        <div className="absolute top-0 lg:top-4 w-full h-full text-left justify-center">
          <h2 className="font-bold  pt-3 text-secondary text-lg pl-4 lg:text-2xl  lg:pl-[2rem]">
            What Is <span className="text-primary">Social</span>
          </h2>
          <h2 className="font-bold text-primary text-lg pl-4 lg:text-2xl lg:pl-[rem] before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/pages/orientation/yellow-underline.png')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 relative inline-block">
            Emotional Learning
          </h2>
          <h4 className="text-secondary text-[11px] pl-4 mt-3 leading-1">
            & Why it forms the second pillar
            <br /> of Quality Education & Holistic
            <br /> Learning?
          </h4>
        </div>
        <div>
          <img
            src={mindIcon}
            alt=""
            className="w-[97px] lg:w-[157px] lg:pr-[40px] md:w-[137px] md:pr-[40px]"
          />
        </div>
      </div>
    ),
    duration: "15 Mins",

    videoId: "1015354619",
    videoTitle: "How crowdfunding enables ideas to become reality!",
    videoThumbnail: videoThumbnail,

    description:
      "Learning through experimentation vs. learning through theory? After extensive research and discussions with experts, we made a pivotal decision to shift our focus to experiential learning. This has significantly improved the quality of education for our children while strengthening their literacy and numeracy foundations. Discover more about this third pillar in this chapter.",
    discussionTitle: (
      <h2 className="text-secondary text-center">
        Share your opinions, feelings, doubts or questions here
      </h2>
    ),
    discussionPlaceholder: "",
    nextTitle: "Academics through experiential learning",
    nextDescription: "Learn more about Muskurahat’s Journey",
    nextImage: videoThumbnail,
    popupAt: [3, 7],
    stars: true,
    questions: [
      {
        timestamp: 79,
        question:
          "Who is foremost responsible for giving a child good upbringing?",
        options: ["a. Parents", "b. Teachers", "c. Society"],
      },
      {
        timestamp: 300,
        question: "Did you stop lying after reading that story?",
        options: ["a. Yes", "b. No"],
      },
      {
        timestamp: 898,
        question: "Did we really learn life skills in school?",
        options: ["a. Yes", "b. No"],
      },
    ],
  },
  // 5
  {
    titleComponent: (
      <div className="relative flex lg:justify-between md:justify-between">
        <img
          src={sidebar}
          alt="Your Image"
          className="w-[280px] h-[180px] lg:w-[330px] md:w-[18rem]"
        />
        <div className="absolute top-0 lg:top-4 w-full h-full text-left justify-center">
          <h2 className="font-bold  pt-3 text-secondary text-lg pl-4 lg:text-2xl  lg:pl-[2rem]">
            Academics Through
          </h2>
          <h2 className="font-bold text-primary text-lg pl-4 lg:text-2xl lg:pl-[rem] before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/pages/orientation/yellow-underline.png')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 relative inline-block">
            Experiential Learning
          </h2>
          <h4 className="text-secondary text-[11px] pl-4 mt-3 leading-1">
            and importance of foundational
            <br /> literacy - Third Pillar Of Quality
            <br />
            Education & Holistic Learning
          </h4>
        </div>
        <div>
          <img
            src={mindIcon}
            alt=""
            className="w-[97px] lg:w-[157px] lg:pr-[40px] md:w-[137px] md:pr-[40px]"
          />
        </div>
      </div>
    ),
    duration: "13 Mins",
    videoId: "1015477575",
    videoTitle: "How crowdfunding enables ideas to become reality!",
    videoThumbnail: videoThumbnail,

    description:
      "Life skills are the building blocks of a brighter future for our children. These skills give them the confidence to face challenges, the creativity to think outside the box, and the courage to dream big. At Muskurahat, we believe that helping children develop these skills is important to help them unlock their true potential.",
    discussionTitle: (
      <h2 className="text-secondary text-center">
        Share your opinions, feelings, doubts or questions here
      </h2>
    ),
    discussionPlaceholder: "",

    nextTitle: "A Vision To Future Proof The Next Generation, Today",
    nextDescription: "Learn more about Muskurahat’s Journey",
    nextImage: videoThumbnail,
    popupAt: [3, 7],
    stars: true,
    questions: [
      {
        timestamp: 33,
        question: "Did Online Education Help You?  Was it impactful?",
        options: ["a. Yes", "b. No"],
      },
      {
        timestamp: 510,
        question:
          "How are we able to execute activities for experiential learning?",
        options: ["a. Freelancers", "b. Textbooks"],
      },
    ],
  },
  // 6
  {
    titleComponent: (
      <div className="relative flex lg:justify-between md:justify-between">
        <img
          src={sidebar}
          alt="Your Image"
          className="w-[280px] h-[180px] lg:w-[330px] md:w-[18rem]"
        />
        <div className="absolute top-3 lg:top-4 w-full h-full text-left justify-center">
          <h2 className="font-bold  pt-3 text-secondary text-lg pl-4 lg:text-2xl  lg:pl-[2rem]">
            A Vision To Future
          </h2>
          <h2 className="font-bold text-secondary text-lg pl-4 lg:text-2xl lg:pl-[rem] relative ">
            Proof The Next
          </h2>

          <h2 className="font-bold text-primary text-lg pl-4 lg:text-2xl lg:pl-[rem] before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/pages/orientation/yellow-underline.png')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 relative inline-block">
            Generation, Today
          </h2>
        </div>
        <div>
          <img
            src={mindIcon}
            alt=""
            className="w-[97px] lg:w-[157px] lg:pr-[40px] md:w-[137px] md:pr-[40px]"
          />
        </div>
      </div>
    ),
    duration: "7 Mins",

    videoId: "1015477252",
    videoTitle: "How crowdfunding enables ideas to become reality!",
    videoThumbnail: videoThumbnail,

    description:
      "Here’s a quick recap of the three pillars of our flagship initiative, Project Keytaab, and how we’re working towards the holistic development of our children. Our approach ensures overall growth, rather than just addressing individual problem areas, with the goal of helping them become happy, independent, and socially responsible citizens of India.",
    discussionTitle: (
      <h2 className="text-secondary text-center">
        Share your opinions, feelings, doubts or questions here
      </h2>
    ),
    discussionPlaceholder: "",

    nextTitle: "The Cost behind enabling quality education for our children.",
    nextDescription: "Learn more about Muskurahat’s Journey",
    nextImage: videoThumbnail,
    popupAt: [3, 7],
    stars: true,
  },
  // 7
  {
    titleComponent: (
      <div className="relative flex lg:justify-between md:justify-between">
        <img
          src={sidebar}
          alt="Your Image"
          className="w-[320px] h-[180px] lg:w-[330px] md:w-[18rem]"
        />
        <div className="absolute top-3 lg:top-4 w-full h-full text-left justify-center">
          <h2 className="font-bold  pt-3 text-secondary text-lg pl-4 lg:text-2xl  lg:pl-[2rem]">
            The Cost behind enabling
          </h2>
          <h2 className="font-bold text-secondary text-lg pl-4 lg:text-2xl lg:pl-[rem] relative ">
            quality education for
          </h2>

          <h2 className="font-bold text-primary text-lg pl-4 lg:text-2xl lg:pl-[rem] before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/pages/orientation/yellow-underline.png')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 relative inline-block">
            our children
          </h2>
        </div>
        <div>
          <img
            src={mindIcon}
            alt=""
            className="w-[97px] lg:w-[157px] lg:pr-[40px] md:w-[137px] md:pr-[40px]"
          />
        </div>
      </div>
    ),
    duration: "8 Mins",

    videoId: "1015477321",
    videoTitle: "How crowdfunding enables ideas to become reality!",
    videoThumbnail: videoThumbnail,

    description:
      "Let’s take a closer look at the costs involved in running our organization and building a system that delivers quality education to our children. This is our effort to highlight the importance of raising funds for an organization like ours and to clarify the distinction between social work and charity.",
    discussionTitle: (
      <h2 className="text-secondary text-center">
        Share your opinions, feelings, doubts or questions here
      </h2>
    ),
    discussionPlaceholder: "",

    nextTitle: "Master The Art Of Storytelling & building conviction",
    nextDescription: "Learn more about Muskurahat’s Journey",
    nextImage: videoThumbnail,
    popupAt: [3, 7],
    stars: true,
    questions: [
      {
        timestamp: 19,
        question: "Can you guess which is our biggest cost?",
        options: ["a. Rents", "b. Teachers", "c. Management"],
      },
      {
        timestamp: 219,
        question: "Do you all agree with the cost breakup?",
        options: ["a. Yes", "b. No"],
      },
      {
        timestamp: 271,
        question: "Do you think Muskurahat should take books as donation?",
        options: ["a. Yes", "b. No"],
      },
      {
        timestamp: 371,
        question: "Can you guess who is our biggest stationery donors?",
        options: ["a. Navneet", "b. Camlin", "c. Private School"],
      },
    ],
  },
  // 8
  {
    titleComponent: (
      <div className="relative flex lg:justify-between md:justify-between">
        <img
          src={sidebar}
          alt="Your Image"
          className="w-[280px] h-[180px] lg:w-[330px] md:w-[18rem]"
        />
        <div className="absolute top-3 lg:top-4 w-full h-full text-left justify-center">
          <h2 className="font-bold  pt-3 text-secondary text-lg pl-4 lg:text-2xl  lg:pl-[2rem]">
            Master The Art Of
          </h2>
          <h2 className="font-bold text-secondary text-lg pl-4 lg:text-2xl lg:pl-[rem] relative ">
            Storytelling &
          </h2>

          <h2 className="font-bold text-primary text-lg pl-4 lg:text-2xl lg:pl-[rem] before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/pages/orientation/yellow-underline.png')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 relative inline-block">
            building conviction
          </h2>
        </div>
        <div>
          <img
            src={mindIcon}
            alt=""
            className="w-[97px] lg:w-[157px] lg:pr-[40px] md:w-[137px] md:pr-[40px]"
          />
        </div>
      </div>
    ),
    duration: "9 Mins",

    videoId: "1015477403",
    videoTitle: "How crowdfunding enables ideas to become reality!",
    videoThumbnail: videoThumbnail,

    description:
      "Earning someone’s trust and confidence takes significant effort. However, with your dedication to improving the lives of underprivileged children and the organization we’ve built to ensure their quality education, we have the potential to inspire belief in our vision and ideas.",
    discussionTitle: (
      <h2 className="text-secondary text-center">
        Share your opinions, feelings, doubts or questions here
      </h2>
    ),
    discussionPlaceholder: "",

    nextTitle: "Thank You!",
    nextDescription: "Learn more about Muskurahat’s Journey",
    nextImage: videoThumbnail,
    popupAt: [3, 7],
    stars: true,
  },
  // 9
  {
    titleComponent: (
      <div className="relative flex lg:justify-between md:justify-between">
        <img
          src={sidebar}
          alt="Your Image"
          className="w-[200px] h-[100px] lg:w-[330px] md:w-[18rem]"
        />
        <div className="absolute top-3 lg:top-4 w-full h-full text-left justify-center">
          <h2 className="font-bold  pt-3 text-secondary text-lg pl-4 lg:text-2xl  lg:pl-[2rem]">
            Thank You!
          </h2>
        </div>
        <div>
          <img
            src={mindIcon}
            alt=""
            className="w-[97px] lg:w-[157px] lg:pr-[40px] md:w-[137px] md:pr-[40px]"
          />
        </div>
      </div>
    ),
    duration: "1 Min",

    videoId: "1015477545",
    videoTitle: "How crowdfunding enables ideas to become reality!",
    videoThumbnail: videoThumbnail,

    description:
      "With heartfelt gratitude, we hope we've given you a deeper understanding of everything Muskurahat does to provide quality education for our children. Your support will help us continue these efforts, and we look forward to having you by our side on this journey!",
    discussionTitle: (
      <h2 className="text-secondary text-center">
        Share your opinions, feelings, doubts or questions here
      </h2>
    ),
    discussionPlaceholder: "",

    nextTitle: "Thank You!",
    nextDescription: "Learn more about Muskurahat’s Journey",
    nextImage: videoThumbnail,
    popupAt: [3, 7],
    stars: true,
  },
];

export default chapters;
