import { AuthContext } from "context/Auth";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import closeIcon from "assets/images/icons/close.png";

const OrientationPopup = ({ setIsOrientationPopupVisible }) => {
  const { profile } = useContext(AuthContext);
  // eslint-disable-next-line no-unused-vars
  const [timeLeft, setTimeLeft] = useState(0);
  const [heading, setHeading] = useState("DEADLINE: 72 HOURS");
  const [subHeading, setSubHeading] = useState(
    "Start your internship by completing the orientation ",
  );
  const [buttonText, setButtonText] = useState("START ORIENTATION");

  useEffect(() => {
    try {
      if (!profile?.orientation?.deadline) {
        setTimeLeft(72 * 60 * 60);
        setHeading("DEADLINE: 72 HOURS");
        setSubHeading("Start Your Internship By Completing The Orientation");
        setButtonText("START ORIENTATION");
      }
      if (profile?.orientation?.deadline) {
        if (
          Date.parse(profile.orientation.deadline.timestamp.toDate()) -
            Date.now() <
          0
        ) {
          setHeading("ALERT");
          setSubHeading(
            "THE INTERNSHIP STANDS INCOMPLETE WITHOUT COMPLETING THE ORIENTATION",
          );
          setButtonText("FINISH ORIENTATION");
        } else {
          setTimeLeft(
            Math.floor(
              (Date.parse(profile.orientation.deadline.timestamp.toDate()) -
                Date.now()) /
                1000,
            ),
          );

          setHeading(
            `${
              profile.orientation.progress
                ? ((10 - Object.keys(profile.orientation.progress).length) *
                    100) /
                    10 +
                  "%"
                : "100%"
            } IS STILL LEFT`,
          );
          setSubHeading("Hours Left To Finish The Orientation");
          setButtonText("FINISH ORIENTATION");
          const interval = setInterval(() => {
            setTimeLeft((prev) => prev - 1);
          }, 1000);

          return () => clearInterval(interval);
        }
      }
    } catch (error) {
      window.location.reload();
    }
  }, [profile]);
  return (
    <div className="absolute top-0 left-0 h-full w-full bg-opacity-50 bg-black z-[11] p-5 flex justify-center items-center">
      <div className="bg-white w-full border-[2px] border-solid border-black rounded-lg">
        <div className="bg-white p-2 rounded-md flex justify-between">
          <h3 className="font-bold text-primary text-sm">{heading}</h3>
          <button
            className={`h-6 w-6 p-3`}
            style={{
              background: `url(${closeIcon})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
            onClick={() => {
              // localstorage set current time
              localStorage.setItem("orientation-popup", new Date());
              setIsOrientationPopupVisible(false);
            }}
          ></button>
        </div>
        <div className="bg-primary rounded-b-md p-2 flex justify-center flex-col gap-5">
          <div className="flex gap-1 justify-center items-center">
            <div className="text-[36px] text-secondary font-bold bg-[linear-gradient(to_bottom,_#E0DFDF_50%,_white_50%)] min-w-[40px] text-center p-2 rounded-sm">
              {String(Math.floor(timeLeft / 3600))
                .padStart(2, "0")
                .substring(0, 1)}
            </div>
            <div className="text-[36px] text-secondary font-bold bg-[linear-gradient(to_bottom,_#E0DFDF_50%,_white_50%)] min-w-[40px] text-center p-2 rounded-sm">
              {String(Math.floor(timeLeft / 3600))
                .padStart(2, "0")
                .substring(1, 2)}
            </div>
            <div className="text-white text-[40px]">:</div>
            <div className="text-[36px] text-secondary font-bold bg-[linear-gradient(to_bottom,_#E0DFDF_50%,_white_50%)] min-w-[40px] text-center p-2 rounded-sm">
              {String(Math.floor((timeLeft % 3600) / 60))
                .padStart(2, "0")
                .substring(0, 1)}
            </div>
            <div className="text-[36px] text-secondary font-bold bg-[linear-gradient(to_bottom,_#E0DFDF_50%,_white_50%)] min-w-[40px] text-center p-2 rounded-sm">
              {String(Math.floor((timeLeft % 3600) / 60))
                .padStart(2, "0")
                .substring(1, 2)}
            </div>
            <div className="text-white text-[40px]">:</div>
            <div className="text-[36px] text-secondary font-bold bg-[linear-gradient(to_bottom,_#E0DFDF_50%,_white_50%)] min-w-[40px] text-center p-2 rounded-sm">
              {String(timeLeft % 60)
                .padStart(2, "0")
                .substring(0, 1)}
            </div>
            <div className="text-[36px] text-secondary font-bold bg-[linear-gradient(to_bottom,_#E0DFDF_50%,_white_50%)] min-w-[40px] text-center p-2 rounded-sm">
              {String(timeLeft % 60)
                .padStart(2, "0")
                .substring(1, 2)}
            </div>
          </div>
          <h3 className="text-white text-lg font-bold text-center">
            {subHeading}
          </h3>
          <Link
            to="/user/orientation"
            className="bg-white text-primary text-sm rounded-md font-bold self-center text-center px-3 py-2"
          >
            {buttonText}
          </Link>
        </div>
      </div>
    </div>
  );
};
export default OrientationPopup;
