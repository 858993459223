import UserLoader from "components/UserLoader";
import { AuthContext } from "context/Auth";
import ShowDashboardContext from "context/ShowDashboard";
import { useContext, useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router";

const PrivateOutlet = () => {
  const { isUserLoading, profile, user: auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const { showDashboard } = useContext(ShowDashboardContext);

  // use effect to check if profile has been welcomed before or not. if not, navigate to welcome component
  useEffect(() => {
    if (profile) {
      if (!profile.welcomed) {
        // react router navigate
        navigate("/user/welcome");
      } else if (!profile.onboarded) {
        // react router navigate
        navigate("/user/onboarding");
      } else if (!showDashboard) {
        // react router navigate
        navigate("/user/dashboard-welcome");
      }
    }
  }, [profile, showDashboard]);

  // TODO: Figure out why we need to return a <UserLoader /> here
  return isUserLoading ? (
    <UserLoader />
  ) : auth ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateOutlet;
