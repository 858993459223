import { Link, useLocation } from "react-router-dom";
import HomeIcon from "./HomeIcon";
import InsightsIcon from "./InsightsIcon";
import DonationsIcon from "./DonationsIcon";
import ExploreIcon from "./ExploreIcon";
import CommunityIcon from "./CommunityIcon";

const BottomMenu = () => {
  const location = useLocation();
  const navs = [
    {
      path: "/user/dashboard",
      icon: HomeIcon,
      text: "Home",
    },
    {
      path: "/user/insights",
      icon: InsightsIcon,
      text: "Insights",
    },
    {
      path: "/user/donations",
      icon: DonationsIcon,
      text: "Donations",
    },
    {
      path: "/user/explore",
      icon: ExploreIcon,
      text: "Explore",
    },
    {
      path: "/user/community",
      icon: CommunityIcon,
      text: "Community",
    },
  ];
  return (
    <div className="pb-24">
      <div
        className="flex px-1 fixed max-w-[600px] bg-white w-full bottom-0 rounded-t-[20px] z-10 justify-between"
        style={{ boxShadow: "0px -3px 10px -3px rgba(0, 0, 0, 0.25)" }}
      >
        {navs.map((nav, index) => (
          <Link key={index} to={nav.path} className="p-5">
            <nav.icon isActive={location.pathname === nav.path} />
            <p
              className={`font-semibold text-[8px] ${
                location.pathname === nav.path ? "text-primary" : "text-black"
              }`}
            >
              {nav.text}
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BottomMenu;
