/* eslint-disable no-unused-vars */
import wrapper from "assets/images/Dashboard/c5.png";
import checks from "assets/images/backgrounds/checks.png";
import backArrow from "assets/images/icons/back-arrow.png";
import PlayIcon from "assets/images/icons/play.svg";
import chapters from "config/chapters";
import { db } from "config/firebase";

import Avatar from "components/Avatar";
import { getRandomColor } from "config/helpers";
import { AuthContext } from "context/Auth";
import NotificationContext from "context/Notification";
import dayjs from "dayjs";
import {
  addDoc,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import useDocumentTitle from "hooks/useDocumentTitle";
import useLayoutConfig from "hooks/useLayoutConfig";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import VideoPlayer from "./VideoPlayer";
import { progress } from "framer-motion";

const Chapter = () => {
  const { profile } = useContext(AuthContext);
  const { setNotificationMessage } = useContext(NotificationContext);

  const [nextChapterEnabled, setNextChapterEnabled] = useState(false);

  const [message, setMessage] = useState("");
  const [discussionPosts, setDiscussionPosts] = useState(null);

  const { id } = useParams();
  const chapterIndex = id.split("-")[1] - 1;
  const chapter = chapters[chapterIndex];
  useDocumentTitle(`Muskurahat Foundation | Training Module - ${id}`);

  // is next chapter enabled ?
  useEffect(() => {
    if (profile?.orientation?.progress) {
      const progress = profile?.orientation?.progress;
      const completedChapters = Object.keys(progress).length;
      console.log(completedChapters, Number(id.split("-")[1]));
      if (completedChapters >= Number(id.split("-")[1])) {
        setNextChapterEnabled(true);
      }
    }
  }, [profile, id]);

  // is current chapter enabled ?
  useEffect(() => {
    if (profile?.orientation?.progress) {
      const progress = profile?.orientation?.progress;
      const completedChapters = Object.keys(progress).length;
      if (completedChapters >= Number(id.split("-")[1])) {
        return;
      } else {
        navigate(`/user/orientation/chapter-${completedChapters + 1}`);
      }
    } else {
      navigate("/user/orientation/chapter-1");
    }
  }, []);

  const fetchDiscussionPosts = async () => {
    const q = query(
      collection(db, "orientation", id, "discussion"),
      where("isApproved", "==", true),
      orderBy("timestamp", "desc"),
      limit(15),
    );
    const discussion = await getDocs(q);
    const temp = [];
    discussion.docs.map((doc) =>
      temp.push({ id: doc.id, color: getRandomColor(), ...doc.data() }),
    );
    setDiscussionPosts(temp);
  };

  useEffect(() => {
    fetchDiscussionPosts();
  }, []);

  useLayoutConfig("red", false);

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(`/user/orientation`);
  };

  const overlayStyle = {
    backgroundPosition: "center center",
    backgroundColor: "#fff",
    backgroundImage: `url(${checks})`,
  };

  const addMessage = async () => {
    if (message) {
      await addDoc(collection(db, "orientation", id, "discussion"), {
        message,
        user: {
          referenceCode: profile.referenceCode,
          avatar: profile.avatar,
          id: profile.id,
          name: profile.name,
        },
        timestamp: serverTimestamp(),
        isApproved: false,
      });

      setNotificationMessage("Your message is sent for an approval");
      setMessage("");
    }
  };

  return (
    <div className="bg-primary">
      <div className="main bg-[#F1F1F1] relative rounded-b-[33px] shadow-[0px_3px_13px_6px_#00000040] pb-10">
        <div style={overlayStyle} className="shadow-lg">
          <div className="text-white text-center py-12">
            <Link to="/user/orientation">
              <img
                src={backArrow}
                alt=""
                className="absolute left-5 top-8 -translate-y-1/2 w-5"
              />
            </Link>

            <div className="px-10">
              <div className="flex gap-2">
                <div className="h-1 w-full bg-[#D9D9D9] rounded-xl my-10 relative">
                  <div
                    className={`absolute left-0 top-0 h-full rounded-xl bg-primary`}
                    style={{
                      width: `${Number(
                        ((profile?.orientation?.progress
                          ? Object.keys(profile?.orientation?.progress)
                          : []
                        ).length /
                          (chapters.length + 1)) *
                          100,
                      ).toFixed(2)}%`,
                    }}
                  ></div>
                </div>
                <div className="text-primary self-center font-bold">
                  {Number(
                    ((profile?.orientation?.progress
                      ? Object.keys(profile?.orientation?.progress)
                      : []
                    ).length /
                      (chapters.length + 1)) *
                      100,
                  ).toFixed(2)}
                  %
                </div>
              </div>
            </div>

            {chapter.titleComponent}
            <div className="mx-5 p-5 bg-[#F7F7F7] rounded-md shadow-[0px_2px_4px_0px_#00000040]">
              <div>
                <VideoPlayer
                  chapter={chapter}
                  video={chapter.videoId}
                  thumbnail={chapter.videoThumbnail}
                  questions={chapter.questions}
                />
              </div>

              <div className="mx-auto">
                <h1 className="font-bold text-secondary text-sm text-left pt-8">
                  Description:
                </h1>
                <h1 className="font-bold text-primary text-[8px] text-left">
                  (Duration: {chapter.duration})
                </h1>
                <p className="font-base text-black text-xs sm:text-sm md:text-base text-justify pt-4 leading-4">
                  {chapter.description}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="relative bg-[#F1F1F1]" id="discussion">
          <img src={wrapper} className="w-4/5 h-full mx-auto" alt="" />

          <div className="text-tertiary text-lg lg:text-2xl absolute top-1/2 left-1/2 font-bold transform -translate-x-1/2 -translate-y-2/3">
            {chapter.discussionTitle}
          </div>
        </div>
        <div className="bg-white mx-6 rounded-2xl py-5 shadow-[0px_4px_4px_0px_#00000040]">
          <div className="flex relative py-6 px-4 h-full min-h-[150px] gap-2">
            <div className="w-[50px] self-start">
              <button
                className={`relative h-[40px] w-[40px] bg-[url(assets/images/icons/profile.webp)] bg-cover`}
                onClick={addMessage}
              ></button>
            </div>
            <div className="w-full">
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    addMessage();
                  }
                }}
                className="w-full h-full border border-black rounded-3xl px-4 pr-10 text-xs bg-[#f4f4f4] text-[#A5a5a5] pt-4"
                type="text"
                placeholder={chapter.discussionPlaceholder}
              />
            </div>
            <div className="w-[40px] self-end">
              <button
                className={`relative h-[39px] w-[40px] bg-[url(assets/images/icons/send.png)] bg-cover`}
                onClick={addMessage}
              ></button>
            </div>
          </div>
          <hr />
          <div className="h-[200px] overflow-y-scroll">
            {discussionPosts === null ? (
              <div className="text-center mt-5">Loading</div>
            ) : discussionPosts.length === 0 ? (
              <div className="text-center mt-5">No Posts Yet</div>
            ) : (
              discussionPosts.map((post, i) => (
                <div key={i} className="flex my-4 px-5 gap-1">
                  <div className="w-2/12">
                    <Avatar
                      size={40}
                      avatarNumber={post.user.avatar}
                      borderColor="#d9d9d9"
                    />
                  </div>
                  <div className="w-11/12">
                    <div className="flex justify-between">
                      <p
                        style={{ color: post.color }}
                        className="text-[8px] font-bold"
                      >
                        {post.user.referenceCode}
                      </p>
                      <p className="text-[#6f6f6f] text-[7px]">
                        {dayjs(post.timestamp.toDate()).format(
                          "MMMM D, YYYY [a]t h:mm A",
                        )}
                      </p>
                    </div>
                    <p className="text-black text-[8px] text-justify">
                      {post.message}
                    </p>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      <div className="bg-white m-5 mt-10 mb-5 p-7 rounded-[9px] shadow-[0px_4px_4px_0px_#00000040]">
        {nextChapterEnabled ? (
          <Link
            to={
              chapters.length !== chapterIndex + 1
                ? `/user/orientation/chapter-${chapterIndex + 2}`
                : `/user/orientation-end`
            }
          >
            {chapters.length !== chapterIndex + 1 ? (
              <div className="p-7 pb-3 pt-0 pl-0">
                <h3 className="text-tertiary text-sm font-bold">Up Next:</h3>
                <p className="text-xs text-secondary font-medium">
                  {chapter.nextDescription}
                </p>
              </div>
            ) : null}

            <div className="flex bg-tertiary rounded-lg">
              {chapters.length !== chapterIndex + 1 ? (
                <div className="w-[300px] rounded-lg">
                  <div
                    style={{ backgroundImage: `url(${chapter.nextImage})` }}
                    className={`bg-cover relative pt-[56.25%] rounded-lg`}
                  >
                    <img
                      className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                      width={30}
                      src={PlayIcon}
                    />
                  </div>
                </div>
              ) : null}

              {chapters.length !== chapterIndex + 1 ? (
                <div className="px-2 flex">
                  <p className="text-base self-center text-white font-bold line-clamp-2 overflow-ellipsis max-w-[255px]">
                    {chapter.nextTitle}
                  </p>
                </div>
              ) : (
                <div className="px-2 flex w-full">
                  <p className="text-base text-center py-10 w-full text-white font-bold">
                    Complete Orientation
                  </p>
                </div>
              )}
            </div>
          </Link>
        ) : (
          <>
            {chapters.length !== chapterIndex + 1 ? (
              <div className="p-7 pb-3 pt-0 pl-0">
                <h3 className="text-tertiary text-sm font-bold">Up Next:</h3>
                <p className="text-xs text-secondary font-medium">
                  {chapter.nextDescription}
                </p>
              </div>
            ) : null}

            <div
              className="flex bg-gray-400 rounded-lg cursor-pointer"
              onClick={() => {
                setNotificationMessage(
                  "Complete the current chapter to unlock the next chapter",
                );
              }}
            >
              {chapters.length !== chapterIndex + 1 ? (
                <div className="w-[300px] rounded-lg">
                  <div
                    style={{ backgroundImage: `url(${chapter.nextImage})` }}
                    className={`bg-cover relative pt-[56.25%] rounded-lg`}
                  >
                    <img
                      className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                      width={30}
                      src={PlayIcon}
                    />
                  </div>
                </div>
              ) : null}

              {chapters.length !== chapterIndex + 1 ? (
                <div className="px-4 flex">
                  <p className="text-base self-center text-white font-bold line-clamp-2 overflow-ellipsis max-w-[255px]">
                    {chapter.nextTitle}
                  </p>
                </div>
              ) : (
                <div className="px-2 flex w-full">
                  <p className="text-base text-center py-10 w-full text-white font-bold">
                    Complete Orientation
                  </p>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Chapter;
