import checks from "assets/images/backgrounds/checks.png";
import backArrow from "assets/images/icons/back-arrow.png";
import StudentWithBook from "assets/images/pages/orientation/student-with-book.png";
import { AuthContext } from "context/Auth";
import useDocumentTitle from "hooks/useDocumentTitle";
import useLayoutConfig from "hooks/useLayoutConfig";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

const overlayStyle = {
  backgroundPosition: "center center",
  backgroundColor: "#fff",
  backgroundImage: `url(${checks})`,
};

const OrientationEnd = () => {
  const { profile, setProfile } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };
  useLayoutConfig("red", false);
  useDocumentTitle("Muskurahat Foundation | Explore");

  return (
    <div className="bg-primary">
      <div
        style={overlayStyle}
        className="shadow-lg pt-1 rounded-b-[50px]  pb-[202px] mb-[100px] relative"
      >
        <div className="text-white text-center py-12">
          <img
            src={backArrow}
            alt=""
            onClick={handleBackClick}
            className="absolute left-5 top-8 -translate-y-1/2 w-5"
          />
          <div className="px-10">
            <div className="flex gap-2">
              <div className="h-1 w-full bg-[#D9D9D9] rounded-xl my-10 relative">
                <div
                  className={`absolute left-0 top-0 h-full rounded-xl bg-primary`}
                  style={{ width: `100%` }}
                ></div>
              </div>
              <div className="text-primary self-center font-bold">100%</div>
            </div>
          </div>
          <h2 className="text-primary text-center font-bold text-[24px]">
            You have reached the
            <br /> end of your orientation
          </h2>
          <p className="text-secondary text-xl mt-7">
            <strong>Thank you</strong> for choosing{" "}
            <strong className="text-primary">Muskurahat</strong>, choosing
            quality education for underprivileged children and taking{" "}
            <strong className="text-primary">responsibility</strong>{" "}
            <strong>for their quality education.</strong>
          </p>
          <p className="text-secondary font-bold text-xl mt-7">
            The Future Will Remember Us!
          </p>

          <button
            onClick={async () => {
              setProfile({
                ...profile,
                orientation: {
                  ...profile.orientation,
                  progress: {
                    ...profile.orientation.progress,
                    10: { timestamp: new Date() },
                  },
                },
              });
              await navigate("/user/dashboard");
            }}
            className="inline-block bg-secondary text-white text-xl font-bold text-center py-5 px-3 rounded-[9px] mt-7"
          >
            Back To Dashboard ✨
          </button>
          <div className="absolute w-full">
            <img
              src={StudentWithBook}
              className="mt-14 w-[300px] text-center m-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrientationEnd;
