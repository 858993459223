import React from "react";
import Avatar1 from "assets/images/avatars/Avatar_1.webp";
import Avatar2 from "assets/images/avatars/Avatar_2.webp";
import Avatar3 from "assets/images/avatars/Avatar_3.webp";
import Avatar4 from "assets/images/avatars/Avatar_4.webp";
import Avatar5 from "assets/images/avatars/Avatar_5.webp";
import Avatar6 from "assets/images/avatars/Avatar_6.webp";

// Your avatar map
const avatarMap = {
  1: Avatar1,
  2: Avatar2,
  3: Avatar3,
  4: Avatar4,
  5: Avatar5,
  6: Avatar6,
};

function Avatar({ avatarNumber, borderColor, size = 40 }) {
  const avatarStyle = `border-4 rounded-full bg-cover bg-white`;

  return (
    <div
      className={avatarStyle}
      style={{
        width: size,
        height: size,
        maxWidth: "100%",
        borderColor: borderColor,
        backgroundImage: `url(${avatarMap[avatarNumber || 1]})`, // Default to Avatar1 if avatarNumber is not provided
      }}
      alt="Avatar"
    />
  );
}

export default Avatar;
