import NotificationContext from "context/Notification";
import { AnimatePresence } from "framer-motion";
import { useContext, useEffect } from "react";
import { motion } from "framer-motion";

const Path = (props) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="#fff"
    strokeLinecap="round"
    {...props}
  />
);

const Notification = () => {
  const { notificationMessage, setNotificationMessage } =
    useContext(NotificationContext);

  useEffect(() => {
    if (notificationMessage) {
      const timeout = setTimeout(() => {
        setNotificationMessage(null);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [notificationMessage]);

  return (
    <div className="fixed top-3 w-full max-w-[600px] z-[999]">
      <AnimatePresence>
        {notificationMessage && (
          <motion.div
            positiontransition="true"
            initial={{ opacity: 0, y: -30, scale: 0.3 }}
            animate={{
              opacity: 1,
              y: 20,
              scale: 1,
              transition: { duration: 0.3 },
            }}
            exit={{
              opacity: 0,
              y: 0,
              scale: 0.5,
              transition: { duration: 0.3 },
            }}
          >
            <div className="absolute left-1/2 -translate-x-1/2 bg-primary text-white  w-3/4 text-center rounded-2xl text-sm">
              <div className="p-10 relative">
                {notificationMessage}
                <button
                  className="absolute right-0 top-0 p-3"
                  onClick={() => {
                    setNotificationMessage(null);
                  }}
                >
                  <svg width="23" height="23" viewBox="0 0 23 23">
                    <Path d="M 3 16.5 L 17 2.5" />
                    <Path d="M 3 2.5 L 17 16.346" />
                  </svg>
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Notification;
