import Counter from "components/Animation/Counter";
import { AuthContext } from "context/Auth";
import dayjs from "dayjs";
import { Timestamp } from "firebase/firestore";
import { useAnimate } from "framer-motion";
import { useContext, useEffect, useState } from "react";

const animatePopover = (showPopover) => {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    animate(
      ".popover-animate",
      {
        opacity: showPopover ? 1 : 0,
        x: showPopover ? 0 : -10,
      },
      {
        duration: 0.5,
      },
    );
  }, [showPopover]);
  return scope;
};

const DaysLeft = () => {
  const { profile, setProfile } = useContext(AuthContext);
  const [daysLeft, setDaysLeft] = useState(0);
  const [showPopover, setShowPopover] = useState(false);
  const togglePopover = () => setShowPopover(!showPopover);
  const scope = animatePopover(showPopover);
  useEffect(() => {
    if (!profile) return;
    const diffDays = dayjs(profile?.expiresAt.toDate()).diff(dayjs(), "day");
    setDaysLeft(diffDays + 1);
  }, [profile]);

  const getColor = () => {
    if (daysLeft > 15) {
      return "#564292";
    } else if (daysLeft > 7) {
      return "#F6A71C";
    } else {
      return "#E22222";
    }
  };

  const getPercentage = () => {
    return (daysLeft / 30) * 100;
  };
  return (
    <div
      id="days-left"
      className="w-12 h-12 bg-white rounded-full flex justify-center items-center border-[3px] border-solid border-black"
    >
      <div
        className="w-full h-full flex justify-center items-center rounded-full bg-secondary"
        style={{
          background: `conic-gradient(from 0deg at 50% 50%, ${getColor()} 0%, ${getColor()} ${getPercentage()}%, #ffffff00 ${getPercentage()}%, #ffffff00 100%)`,
        }}
      >
        <div
          ref={scope}
          className={`w-8 h-8 relative border-solid border-[1px] border-black bg-white rounded-full flex justify-center items-center font-bold`}
        >
          {daysLeft < 1 ? (
            <button
              onClick={() => {
                setProfile({
                  ...profile,
                  expiresAt: Timestamp.fromDate(
                    dayjs().add(10, "days").toDate(),
                  ),
                });
              }}
            >
              <i className="fa-solid fa-plus"></i>
            </button>
          ) : (
            <button onClick={togglePopover}>
              <Counter from={0} to={daysLeft} duration={2} />
            </button>
          )}
          <div
            style={{ color: getColor() }}
            className={`popover-animate z-10 opacity-0 absolute w-[80px] bg-white text-primary left-14 top-[-5px] p-2 text-xs font-bold rounded-lg after:content-[''] after:absolute after:-left-2 after:top-[7px] after:mx-0 after:my-auto after:w-0 after:h-0 after:border-y-[10px] after:border-solid after:border-y-transparent after:border-r-[10px] after:border-r-white`}
          >
            DAYS LEFT
          </div>
        </div>
      </div>
    </div>
  );
};

export default DaysLeft;
