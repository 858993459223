import { db } from "config/firebase";
import { AuthContext } from "context/Auth";
import { addDoc, collection } from "firebase/firestore";
import { useContext, useState } from "react";

const Question = ({ question }) => {
  const { profile } = useContext(AuthContext);
  const [selected, setSelected] = useState(null);

  const handleOptionClick = async (index) => {
    setSelected(index);
    await addDoc(collection(db, "orientation-quiz"), {
      question: question.question,
      answer: index,
      user: {
        name: profile.name,
        referenceCode: profile.referenceCode,
        avatar: profile.avatar,
        id: profile.id,
      },
    });
  };

  return (
    <div className="absolute bg-transparent top-0 h-full w-full animate-appear">
      <div className="absolute left-1/2 portrait:top-5 top-[20%] lg:top-[35%] -translate-x-1/2 portrait:w-full portrait:px-5">
        <div className="relative portrait:p-3 p-10 border border-tertiary inline-block rounded-tl-3xl rounded-br-3xl">
          <span className="text-secondary font-bold portrait:text-xs">
            {question.question}
          </span>

          <div className="flex gap-3 justify-between absolute portrait:-bottom-7 -bottom-20 translate-y-full w-full left-0">
            {question.options.map((option, j) => (
              <button
                onClick={() => handleOptionClick(j)}
                className={`${
                  selected === j
                    ? "bg-tertiary !text-white *:text-white"
                    : "bg-transparent"
                } transition-all text-secondary font-bold duration-500 p-5 py-3 border w-full border-tertiary rounded-tl-3xl rounded-br-3xl before:content-[''] before:border-[0.5px] before:border-tertiary before:absolute before:top-0 portrait:before:h-7 before:h-20 before:-translate-y-full`}
                key={j}
              >
                {option}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Question;
