import Fish from "assets/images/pages/onboarding/fish.webp";
import HumanGroup from "assets/images/pages/onboarding/group-humans.webp";
import Human2 from "assets/images/pages/onboarding/human-2.webp";
import Human from "assets/images/pages/onboarding/human.webp";
import { AuthContext } from "context/Auth";
import useLayoutConfig from "hooks/useLayoutConfig";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const InternshipOnboarding = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isStepComplete, setIsStepComplete] = useState(false);
  const [showError, setShowError] = useState(false);
  useLayoutConfig("red", false);
  const { profile, setProfile } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    city: "",
    reason: "",
  });

  let navigate = useNavigate();

  useEffect(() => {
    switch (currentStep) {
      case 9:
      case 10:
      case 11:
      case 12:
        setIsStepComplete(false);
        break;
      case 13:
        if (formData.city.length > 0) setIsStepComplete(true);
        else setIsStepComplete(false);
        break;

      case 14:
        if (formData.reason.length > 50) setIsStepComplete(true);
        else setIsStepComplete(false);
        break;
      default:
        setIsStepComplete(true);
    }
  }, [currentStep, formData]);

  //   useEffect(() => {
  //     sendGTMEvent({ event: "intern_form_filled", step: currentStep });
  //   }, [currentStep]);

  const handleNext = () => {
    setShowError(false);
    if (currentStep === steps.length - 1) return;
    setCurrentStep((prev) => prev + 1);
  };

  const handlePrev = () => {
    if (currentStep === 0) return;
    setCurrentStep((prev) => prev - 1);
  };

  // use effect to check if profile has been welcomed before or not. if not, navigate to welcome component
  useEffect(() => {
    if (profile && profile.welcomed) {
      // react router navigate
      navigate("/user/dashboard");
    }
  }, []);

  const handleSubmit = async () => {
    setIsLoading(true);
    let setFormData = new FormData();
    setProfile({
      ...profile,
      onboarded: true,
      onboardedOn: new Date(),
      city: formData.city,
      reason: formData.reason,
    });

    setFormData.append(
      "createdOn",
      `${new Date().toDateString()} ${new Date().toLocaleTimeString()}`,
    );
    setFormData.append("city", formData.city);
    setFormData.append("reason", formData.reason);

    await fetch(
      "https://script.google.com/macros/s/AKfycbz-zR7WFnC_cFv1e5Ls_QQOTVhzIz_xWYeuRK1EaSA0EgAXbC4Emi4hAZ7AIsXzonsI/exec",
      {
        method: "POST",
        body: setFormData,
      },
    );
    // sendGTMEvent({ event: "intern_form_filled", step: "submitted" });

    setIsLoading(false);
  };

  const steps = [
    {
      body: (
        <div className="text-center px-10">
          <h2 className="text-tertiary font-bold text-[18px]">
            Average attention span of a Goldfish is 9 seconds.
          </h2>
          <h2 className="font-bold text-[18px]">
            Average attention span of a human is 8.25 Seconds.
          </h2>
          <h3 className="text-[14px] mt-5">
            So if you&apos;re not ready to{" "}
            <span className="font-bold">commit</span> then this internship IS
            NOT FOR YOU.
          </h3>
          <button
            onClick={handleNext}
            className="bg-primary text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] relative z-10"
          >
            I Can Focus
          </button>
          <div className="absolute bottom-10 left-0 px-10 w-full justify-between flex">
            <img
              alt="Fish"
              src={Fish}
              height={80}
              width={120}
              className="self-center"
            />
            <img alt="Human" src={Human} height={190} width={220} />
          </div>
        </div>
      ),
    },
    {
      body: (
        <div className="text-center px-10">
          <h3 className="text-[14px] mt-">
            This is an opportunity to learn the
          </h3>
          <h2 className="font-bold text-[18px]">
            Skill of the century and solve education related problems for
            children in need
          </h2>
          <h3 className="text-[14px] mt-5">
            Your time and effort can really make a difference.
          </h3>

          <button
            onClick={handleNext}
            className="bg-primary text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] z-10 relative"
          >
            I Am In
          </button>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Human" src={Human2} height={250} width={350} />
          </div>
        </div>
      ),
    },
    {
      body: (
        <div className="text-center px-10">
          <h2 className="font-bold text-[18px]">
            We are working towards future-proofing the next generation, today.
          </h2>
          <h3 className="text-[14px] mt-5">
            We provide access to{" "}
            <span className="font-bold">quality education</span>. It fosters a
            child’s overall development, especially for children who are
            deprived of the bare minimum privileges.
          </h3>

          <button
            onClick={handleNext}
            className="bg-primary text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] z-10 relative"
          >
            Why?
          </button>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Human" src={HumanGroup} height={250} width={350} />
          </div>
        </div>
      ),
    },
    {
      body: (
        <div className="text-center px-10">
          <h3 className="text-[14px] mt-5">
            Access to education has been largely achieved in India. However,{" "}
            <span className="font-bold">quality</span> in{" "}
            <span className="font-bold">education</span> is a challenge to
            overcome.
          </h3>
          <h3 className="text-[14px] mt-5">
            Resulting in children in India being <br />
            <span className="font-bold">schooled</span> but{" "}
            <span className="font-bold text-[18px]">not educated.</span>
          </h3>

          <button
            onClick={handleNext}
            className="bg-primary text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] z-10 relative"
          >
            What can be done?
          </button>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Human" src={HumanGroup} height={250} width={350} />
          </div>
        </div>
      ),
    },
    {
      body: (
        <div className="text-center px-10">
          <h3 className="text-[14px] mt-5">
            We work on three key aspects of child development
          </h3>
          <ul className="font-bold text-[18px] list-none mt-5">
            <li>● &nbsp;Social Emotional Learning</li>
            <li>● &nbsp;Academic Support </li>
            <li>● &nbsp;Mental Well-Being</li>
          </ul>

          <button
            onClick={handleNext}
            className="bg-primary text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] z-10 relative"
          >
            Boy, That’s Deep!
          </button>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Human" src={HumanGroup} height={250} width={350} />
          </div>
        </div>
      ),
    },
    {
      body: (
        <div className="text-center px-10">
          <h3 className="text-[14px] mt-5">
            Do you know 50% applicants drop out by the time they reach here? We
            are grateful you are still with us!{" "}
          </h3>
          <h2 className="font-bold text-[18px] list-none mt-5">
            The future will remember you!
          </h2>

          <button
            onClick={handleNext}
            className="bg-primary text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] z-10 relative"
          >
            Tell Me MORE
          </button>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Human" src={HumanGroup} height={250} width={350} />
          </div>
        </div>
      ),
    },
    {
      body: (
        <div className="text-center px-10">
          <h2 className="font-bold text-[18px] list-none mt-5">
            This is a virtual crowdfunding internship
          </h2>

          <h3 className="text-[14px] mt-5">
            You will advocate for the cause within your network and gather funds
            to support their education. We will utilize the funds to meet their
            educational requirements, such as trained tutors, resources,
            sustenance, and opportunities to reach more children.
          </h3>

          <button
            onClick={handleNext}
            className="bg-primary text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] z-10 relative"
          >
            How Will I Pull That Off?
          </button>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Human" src={HumanGroup} height={250} width={350} />
          </div>
        </div>
      ),
    },
    {
      body: (
        <div className="text-center px-10">
          <h3 className="text-[14px] mt-5">
            We will teach you how to pitch your ideas, design your campaign,
            find your audience, how NGO functions, and the
          </h3>

          <h2 className="font-bold text-[18px] list-none mt-2">
            SKILL OF THE CENTURY & HELP BRIDGE THE ACCESS TO QUALITY EDUCATION.
          </h2>

          <button
            onClick={handleNext}
            className="bg-primary text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] z-10 relative"
          >
            Is There More?
          </button>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Human" src={HumanGroup} height={250} width={350} />
          </div>
        </div>
      ),
    },
    {
      body: (
        <div className="text-center px-10">
          <h3 className="text-[14px] mt-5">
            You will also take home a{" "}
            <span className="font-bold">
              stipend, certificates and boatload of experience
            </span>{" "}
            at the end of your internship.
          </h3>

          <button
            onClick={handleNext}
            className="bg-primary text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] z-10 relative"
          >
            I Am Excited!
          </button>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Human" src={HumanGroup} height={250} width={350} />
          </div>
        </div>
      ),
    },
    {
      body: (
        <div className="text-center px-10">
          <h3 className="text-[14px] mt-5">
            Before we move ahead,
            <br />
            <br />
            During this internship you will be{" "}
            <span className="font-bold">raising funds</span> to enable us to
            educate thousands of children-in-need. *
          </h3>
          <div className="flex flex-col w-3/4 m-auto">
            <label
              className="flex items-center justify-start mt-5 bg-[#E31D130D] has-[:checked]:bg-primary border border-primary rounded-[7px] px-5 py-4 text-primary has-[:checked]:text-white z-10 relative"
              htmlFor="accept"
            >
              <span className="bg-[#E31D131A] border border-[#E31D1333] has-[:checked]:border-white p-3 mr-4 rounded-full h-10 w-10 flex justify-center items-center">
                a
                <input
                  type="radio"
                  onClick={handleNext}
                  id="accept"
                  name="funds-raised"
                  hidden
                />
              </span>{" "}
              I accept
            </label>

            <label
              className="flex items-center justify-start mt-5 bg-[#E31D130D] has-[:checked]:bg-primary border border-primary rounded-[7px] px-5 py-4 text-primary has-[:checked]:text-white z-10 relative"
              htmlFor="reject"
            >
              <span className="bg-[#E31D131A] border border-[#E31D1333] has-[:checked]:border-white p-3 mr-4 rounded-full h-10 w-10 flex justify-center items-center">
                b
                <input
                  type="radio"
                  onClick={() => setShowError(true)}
                  id="reject"
                  name="funds-raised"
                  hidden
                />
              </span>
              I don&apos;t accept
            </label>
            {showError && (
              <p className="mt-5 text-primary animate-shake">
                Error: You can only proceed by Accepting The Requirements Of The
                Internship
              </p>
            )}
          </div>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Human" src={HumanGroup} height={250} width={350} />
          </div>
        </div>
      ),
    },
    {
      body: (
        <div className="text-center px-10">
          <h3 className="text-[14px] mt-5">
            You will be required to reach out to your personal network, tell
            them about the cause and raise funds. *
          </h3>
          <div className="flex flex-col w-3/4 m-auto">
            <label
              className="flex items-center justify-start mt-5 bg-[#E31D130D] has-[:checked]:bg-primary border border-primary rounded-[7px] px-5 py-4 text-primary has-[:checked]:text-white z-10 relative"
              htmlFor="personal-network-accept"
            >
              <span className="bg-[#E31D131A] border border-[#E31D1333] has-[:checked]:border-white p-3 mr-4 rounded-full h-10 w-10 flex justify-center items-center">
                a
                <input
                  type="radio"
                  onClick={handleNext}
                  id="personal-network-accept"
                  name="personal-network"
                  hidden
                />
              </span>{" "}
              I accept
            </label>

            <label
              className="flex items-center justify-start mt-5 bg-[#E31D130D] has-[:checked]:bg-primary border border-primary rounded-[7px] px-5 py-4 text-primary has-[:checked]:text-white z-10 relative"
              htmlFor="personal-network-reject"
            >
              <span className="bg-[#E31D131A] border border-[#E31D1333] has-[:checked]:border-white p-3 mr-4 rounded-full h-10 w-10 flex justify-center items-center">
                b
                <input
                  type="radio"
                  onClick={() => setShowError(true)}
                  id="personal-network-reject"
                  name="personal-network"
                  hidden
                />
              </span>
              I don&apos;t accept
            </label>
            {showError && (
              <p className="mt-5 text-primary animate-shake">
                Error: You can only proceed by Accepting The Requirements Of The
                Internship
              </p>
            )}
          </div>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Human" src={HumanGroup} height={250} width={350} />
          </div>
        </div>
      ),
    },
    {
      body: (
        <div className="text-center px-10">
          <h3 className="text-[14px] mt-5">
            You will be required to maintain 2-way communication on Whatsapp,
            send weekly updates, throughout your internship. *
          </h3>
          <div className="flex flex-col w-3/4 m-auto">
            <label
              className="flex items-center justify-start mt-5 bg-[#E31D130D] has-[:checked]:bg-primary border border-primary rounded-[7px] px-5 py-4 text-primary has-[:checked]:text-white z-10 relative"
              htmlFor="whatsapp-accept"
            >
              <span className="bg-[#E31D131A] border border-[#E31D1333] has-[:checked]:border-white p-3 mr-4 rounded-full h-10 w-10 flex justify-center items-center">
                a
                <input
                  type="radio"
                  onClick={handleNext}
                  id="whatsapp-accept"
                  name="whatsapp"
                  hidden
                />
              </span>{" "}
              I accept
            </label>

            <label
              className="flex items-center justify-start mt-5 bg-[#E31D130D] has-[:checked]:bg-primary border border-primary rounded-[7px] px-5 py-4 text-primary has-[:checked]:text-white z-10 relative"
              htmlFor="whatsapp-reject"
            >
              <span className="bg-[#E31D131A] border border-[#E31D1333] has-[:checked]:border-white p-3 mr-4 rounded-full h-10 w-10 flex justify-center items-center">
                b
                <input
                  type="radio"
                  onClick={() => setShowError(true)}
                  id="whatsapp-reject"
                  name="whatsapp"
                  hidden
                />
              </span>
              I don&apos;t accept
            </label>
            {showError && (
              <p className="mt-5 text-primary animate-shake">
                Error: You can only proceed by Accepting The Requirements Of The
                Internship
              </p>
            )}
          </div>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Human" src={HumanGroup} height={250} width={350} />
          </div>
        </div>
      ),
    },
    {
      body: (
        <div className="text-center px-10">
          <h3 className="text-[14px] mt-5">
            Would you like to sign up for this internship? *
          </h3>
          <div className="flex flex-col w-3/4 m-auto">
            <label
              className="flex items-center justify-start mt-5 bg-[#E31D130D] has-[:checked]:bg-primary border border-primary rounded-[7px] px-5 py-4 text-primary has-[:checked]:text-white z-10 relative"
              htmlFor="yes"
            >
              <span className="bg-[#E31D131A] border border-[#E31D1333] has-[:checked]:border-white p-3 mr-4 rounded-full h-10 w-10 flex justify-center items-center">
                a
                <input
                  type="radio"
                  onClick={handleNext}
                  id="yes"
                  name="signup"
                  hidden
                />
              </span>{" "}
              Yes
            </label>

            <label
              className="flex items-center justify-start mt-5 bg-[#E31D130D] has-[:checked]:bg-primary border border-primary rounded-[7px] px-5 py-4 text-primary has-[:checked]:text-white z-10 relative"
              htmlFor="no"
            >
              <span className="bg-[#E31D131A] border border-[#E31D1333] has-[:checked]:border-white p-3 mr-4 rounded-full h-10 w-10 flex justify-center items-center">
                b
                <input
                  type="radio"
                  onClick={() => setShowError(true)}
                  id="no"
                  name="signup"
                  hidden
                />
              </span>
              No
            </label>

            {showError && (
              <p className="mt-5 text-primary animate-shake">
                Error: You can only proceed by Accepting The Requirements Of The
                Internship
              </p>
            )}
          </div>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Human" src={HumanGroup} height={250} width={350} />
          </div>
        </div>
      ),
    },

    {
      body: (
        <div className="text-center px-10">
          <h3 className="text-[18px] mt-5">
            <span className="font-bold">Which city are you from? *</span>
          </h3>
          <input
            onChange={(e) => setFormData({ ...formData, city: e.target.value })}
            value={formData.city}
            type="text"
            placeholder="(Type your answer here)"
            className="w-full bg-transparent border-b-2 border-black text-center text-[14px] mt-10"
          />
          <button
            disabled={!isStepComplete}
            onClick={handleNext}
            className="bg-primary disabled:bg-grey disabled:cursor-not-allowed disabled:opacity-50 text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] z-10 relative"
          >
            OK
          </button>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Human" src={HumanGroup} height={250} width={350} />
          </div>
        </div>
      ),
    },

    {
      body: (
        <div className="text-center px-10">
          <h3 className="text-[18px] mt-5]">
            Why would you like to help the{" "}
            <span className="font-bold">thousands of children</span> that are in
            need of quality education? *
          </h3>
          <h4 className="text-[14px] mt-5">
            (we would like to hear from you and{" "}
            <span className="font-bold">NOT CHATGPT</span>, please answer by
            yourself and with minimum 10 words)
          </h4>
          <input
            onChange={(e) =>
              setFormData({ ...formData, reason: e.target.value })
            }
            value={formData.reason}
            type="text"
            placeholder="(Type your answer here)"
            className="w-full bg-transparent border-b-2 border-black text-center text-[14px] mt-10"
          />
          <button
            disabled={!isStepComplete || isLoading}
            onClick={async () => {
              await handleSubmit();
              handleNext();
            }}
            className="bg-primary disabled:bg-grey disabled:cursor-not-allowed  text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] z-10 relative"
          >
            {isLoading ? (
              // loading icon
              <svg
                className="animate-spin h-8 w-8"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V4a10 10 0 00-1.93 2.4"
                ></path>
              </svg>
            ) : (
              "Submit"
            )}
          </button>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Human" src={HumanGroup} height={250} width={350} />
          </div>
        </div>
      ),
    },
    {
      body: (
        <div className="text-center px-10">
          <h2 className="font-bold text-[18px]">
            🎉 Awesome! Form Submitted! ✅
          </h2>
          <h3 className="text-[14px] mt-5">
            You’re all set! You might have questions, but don&apos;t worry,{" "}
            <strong>
              our team will reach out within 48 hours to guide you further
            </strong>
            <br />
            <br />
            In the meantime, your first mission is clear: <br />
            <strong>Complete your orientation on the dashboard!</strong>
          </h3>

          <button
            onClick={() => navigate("/user/dashboard")}
            className="bg-primary text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] z-10 relative"
          >
            Take me to my dashboard
          </button>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Human" src={Human2} height={250} width={350} />
          </div>
        </div>
      ),
    },
    // {
    //   body: (
    //     <div className="text-center px-10">
    //       <h2 className="font-bold text-[18px]">
    //         Your one step today is going to change the future of many
    //       </h2>
    //       <h3 className="text-[14px] mt-5">
    //         We are going to reach out to you within 48 hours. Meanwhile do share
    //         the word about this internship, and follow us on{" "}
    //         <Link
    //           href="https://www.instagram.com/muskurahat_foundation"
    //           target="_blank"
    //           className="text-primary font-bold"
    //         >
    //           Instagram
    //         </Link>{" "}
    //         to learn more about how are we serving our children.{" "}
    //       </h3>

    //       <button
    //         onClick={() =>
    //           navigate(
    //             "https://api.whatsapp.com/send?text=Hey%2C%20check%20out%20this%20Internship%20opportunity%20https%3A%2F%2Fmuskurahat.org.in%2Finternship-application%3Futm_source%3Dref",
    //           )
    //         }
    //         className="bg-primary text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] z-10 relative"
    //       >
    //         REFER THIS INTERNSHIP TO A FRIEND
    //       </button>
    //       <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
    //         <img alt="Human" src={Human2} height={250} width={350} />
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  return (
    <div className="h-[100dvh]">
      <div
        className={`h-dvh h-full bg-[url('assets/images/pages/onboarding/background.webp')] bg-cover overflow-x-hidden relative`}
      >
        <div
          style={{
            width: steps.length * 100 + "%",
            // width: "100%",
            transform: `translateX(-${currentStep * (100 / steps.length)}%)`,
          }}
          className={`h-full flex justify-center items-center h-dvh transition-transform duration-500 ease-in-out`}
        >
          {steps.map((step, index) => (
            <div
              key={index}
              className="w-screen h-full flex justify-center items-center relative"
            >
              {step.body}
            </div>
          ))}
        </div>
        <div className="absolute bottom-5 right-5 flex gap-1 z-10">
          <button
            className="bg-primary disabled:bg-grey disabled:cursor-not-allowed disabled:opacity-50"
            onClick={handlePrev}
            disabled={currentStep === 0 || currentStep === 19}
          >
            <svg
              stroke="currentColor"
              strokeWidth="0"
              viewBox="0 0 20 20"
              height="1.4em"
              width="1.4em"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>

          <button
            className="bg-primary disabled:bg-grey disabled:cursor-not-allowed disabled:opacity-50"
            onClick={handleNext}
            disabled={!isStepComplete || steps.length - 1 === currentStep}
          >
            {/* up arrow svg */}
            <svg
              stroke="currentColor"
              strokeWidth="0"
              viewBox="0 0 20 20"
              height="1.4em"
              width="1.4em"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default InternshipOnboarding;
