/* eslint-disable no-unused-vars */
import closeIcon from "assets/images/icons/close.png";
import PlayIcon from "assets/images/icons/play.svg";
import PlayIconPng from "assets/images/icons/play.png";

import PauseIcon from "assets/images/icons/pause.png";
import HeartReaction from "assets/images/pages/orientation/heart.png";
import LaughReaction from "assets/images/pages/orientation/laugh.png";
import SadReaction from "assets/images/pages/orientation/sad.png";
import ThumbDownReaction from "assets/images/pages/orientation/thumb-down.png";
import ThumbReaction from "assets/images/pages/orientation/thumb.png";
import { useContext, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/vimeo";
import screenfull from "screenfull";
import Question from "./Question";
import { useNavigate, useParams } from "react-router-dom";
import chapters from "config/chapters";
import { AuthContext } from "context/Auth";

const VideoPlayer = ({ chapter, video, thumbnail, questions }) => {
  const { profile, setProfile } = useContext(AuthContext);

  const [playing, setPlaying] = useState(false);

  const navigate = useNavigate();

  const containerRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showNextChapter, setShowNextChapter] = useState(false);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [videoQuestions, setVideoQuestions] = useState([]);

  const [isOrientationLockUnavailable, setIsOrientationLockUnavailable] =
    useState(false);

  const [isFullscreenUnavailable, setIsFullscreenUnavailable] = useState(false);

  // reactions
  const [hearts, setHearts] = useState([]);
  const [laugh, setLaugh] = useState([]);
  const [sad, setSad] = useState([]);
  const [thumbsDown, setThumbsDown] = useState([]);
  const [thumbsUp, setThumbsUp] = useState([]);
  const { id } = useParams();
  const chapterIndex = id.split("-")[1] - 1;

  if (screenfull.isEnabled) {
    screenfull.on("change", () => {
      if (screenfull.isFullscreen) {
        setIsFullscreen(true);
      }

      if (!screenfull.isFullscreen) {
        setIsFullscreen(false);
        handlePauseVideo();
      }
    });
  }

  const makeFullScreen = async () => {
    if (!screenfull.isFullscreen) {
      if (screenfull.isEnabled) {
        screenfull.request(containerRef.current);

        try {
          screen.orientation.lock("landscape").then(
            (success) => console.log(success),
            (failure) => console.log(failure),
          );
        } catch (e) {
          setIsOrientationLockUnavailable(true);
          console.log(e);
        }
      } else {
        setIsFullscreenUnavailable(true);
      }
    }
  };

  // when PLAY button is clicked or progress is made:
  const handlePlayButtonClicked = () => {
    makeFullScreen();
    handlePlayVideo(true);
  };

  const handlePlayVideo = () => {
    setPlaying(true);
  };

  const handlePauseVideo = () => {
    setPlaying(false);
  };

  useEffect(() => {
    if (questions) {
      // find the question from questions array which is at the current time or 10 seconds after of current time
      const currentQuestion = questions.find(
        (question) =>
          playedSeconds >= question.timestamp &&
          playedSeconds <= question.timestamp + 5,
      );

      if (currentQuestion) {
        // return if question is already added
        if (
          videoQuestions.find((q) => q.question === currentQuestion.question)
        ) {
          return;
        }

        setVideoQuestions((prev) => [...prev, currentQuestion]);
        // remove the question after 10 seconds
        setTimeout(() => {
          setVideoQuestions((prev) =>
            prev.filter((q) => q.timestamp !== currentQuestion.timestamp),
          );
        }, 5000);
      }
    }
  }, [playedSeconds]);

  return (
    <div className="relative w-full" ref={containerRef}>
      <div
        style={{
          background: isFullscreen ? "#564292" : `url('${thumbnail}')`,
          backgroundSize: "cover",
        }}
        className={`player-container relative rounded-md ${
          isFullscreen ? "pt-0" : "pt-[56.25%]"
        } h-full max-h-screen`}
      >
        <ReactPlayer
          playsinline
          width="100%"
          height="100%"
          playing={playing}
          className="absolute top-0 left-0"
          controls={false}
          url={`https://vimeo.com/${video}`}
          onProgress={(e) => {
            setPlayedSeconds(e.playedSeconds);
          }}
          onEnded={(e) => {
            setProfile({
              ...profile,
              orientation: {
                ...profile.orientation,
                progress: {
                  ...profile.orientation.progress,
                  [`${chapterIndex + 1}`]: { timestamp: new Date() },
                },
              },
            });
            setShowNextChapter(true);
          }}
        />
      </div>
      <div className="bg-primary text-white">
        {playing &&
          videoQuestions.map((question, i) => (
            <Question key={i} question={question} />
          ))}
      </div>
      {showNextChapter && (
        <div className="absolute top-0 left-0 h-full w-full flex justify-center align-middle flex-col bg-tertiary p-3 rounded-md transition-all">
          <div className="flex flex-col h-full justify-around content-between">
            <div
              className="flex gap-5"
              onClick={() => {
                // navigate to next chapter
                if (chapters.length !== chapterIndex + 1) {
                  navigate(`/user/orientation/chapter-${chapterIndex + 2}`);
                } else {
                  navigate(`/user/orientation-end`);
                }
              }}
            >
              <div className="w-1/2">
                <div
                  style={{ backgroundImage: `url(${chapter.nextImage})` }}
                  className={`bg-cover relative pt-[56.25%] rounded-lg`}
                >
                  <img
                    className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                    width={30}
                    src={PlayIcon}
                  />
                </div>
              </div>
              <div className="self-center">
                <p className="text-base self-center text-white font-bold line-clamp-2 overflow-ellipsis max-w-[255px]">
                  {chapter.nextTitle}
                </p>
              </div>
            </div>
            <button
              className="text-white bg-secondary px-4 py-2 rounded-md self-center"
              onClick={() => {
                // navigate to next chapter
                if (chapters.length !== chapterIndex + 1) {
                  navigate(`/user/orientation/chapter-${chapterIndex + 2}`);
                } else {
                  navigate(`/user/orientation-end`);
                }
              }}
            >
              Next Chapter
            </button>
          </div>
        </div>
      )}
      {/* Close full screen button */}
      {isFullscreen && (
        <div className="absolute top-2 right-2">
          <button
            className={`h-6 w-6 p-3`}
            style={{
              background: `url(${closeIcon})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
            onClick={() => {
              // exit fullscreen
              screenfull.exit();
            }}
          ></button>
        </div>
      )}
      {/* Reactions */}
      <div
        className={`flex flex-col gap-2 absolute bottom-0 left-2 pb-2 rounded-t-full bg-[#D9D9D980] p-2 ${
          isFullscreen ? "block" : "hidden"
        }`}
      >
        <div className="relative">
          <button
            className={`h-10 w-10`}
            style={{
              background: `url(${ThumbReaction})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
            onClick={() => setThumbsUp((t) => [...t, { id: Date.now() }])}
          ></button>
          {thumbsUp.map((thumb, i) => (
            <img
              key={i}
              src={ThumbReaction}
              alt="heart"
              className="h-10 w-10 animate-floating absolute  opacity-0"
            />
          ))}
        </div>
        <div className="relative">
          <button
            className={`h-10 w-10`}
            style={{
              background: `url(${ThumbDownReaction})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
            onClick={() => setThumbsDown((t) => [...t, { id: Date.now() }])}
          ></button>
          {thumbsDown.map((thumb, i) => (
            <img
              key={i}
              src={ThumbDownReaction}
              alt="heart"
              className="h-10 w-10 animate-floating absolute  opacity-0"
            />
          ))}
        </div>
        <div className="relative">
          <button
            className={`h-10 w-10`}
            style={{
              background: `url(${HeartReaction})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
            onClick={() => setHearts((h) => [...h, { id: Date.now() }])}
          ></button>
          {hearts.map((heart, i) => (
            <img
              key={i}
              src={HeartReaction}
              alt="heart"
              className="h-10 w-10 animate-floating absolute  opacity-0"
            />
          ))}
        </div>
        <div className="relative">
          <button
            className={`h-10 w-10`}
            style={{
              background: `url(${LaughReaction})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
            onClick={() => setLaugh((h) => [...h, { id: Date.now() }])}
          ></button>
          {laugh.map((heart, i) => (
            <img
              key={i}
              src={LaughReaction}
              alt="sad"
              className="h-10 w-10 animate-floating absolute opacity-0"
            />
          ))}
        </div>
        <div className="relative">
          <button
            className={`h-10 w-10`}
            style={{
              background: `url(${SadReaction})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
            onClick={() => setSad((h) => [...h, { id: Date.now() }])}
          ></button>
          {sad.map((heart, i) => (
            <img
              key={i}
              src={SadReaction}
              alt="sad"
              className="h-10 w-10 animate-floating absolute  opacity-0"
            />
          ))}
        </div>
      </div>
      {/* Reactions if full screen is unavailable */}
      {isFullscreenUnavailable && !showNextChapter && (
        <div
          className={`flex justify-center flex-row gap-2 rounded-b-md bg-[#D9D9D980] p-2`}
        >
          <div className="relative">
            {playing ? (
              <button
                className={`h-10 w-10`}
                style={{
                  backgroundImage: `url(${PauseIcon})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                }}
                onClick={() => setPlaying((playing) => !playing)}
              ></button>
            ) : (
              <button
                className={`h-10 w-10`}
                style={{
                  backgroundImage: `url(${PlayIconPng})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                }}
                onClick={() => setPlaying((playing) => !playing)}
              ></button>
            )}
          </div>
          <div className="relative">
            <button
              className={`h-10 w-10`}
              style={{
                background: `url(${ThumbReaction})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
              }}
              onClick={() => setThumbsUp((t) => [...t, { id: Date.now() }])}
            ></button>
            {thumbsUp.map((thumb, i) => (
              <img
                key={i}
                src={ThumbReaction}
                alt="heart"
                className="h-10 w-10 animate-floating absolute  opacity-0"
              />
            ))}
          </div>
          <div className="relative">
            <button
              className={`h-10 w-10`}
              style={{
                background: `url(${ThumbDownReaction})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
              }}
              onClick={() => setThumbsDown((t) => [...t, { id: Date.now() }])}
            ></button>
            {thumbsDown.map((thumb, i) => (
              <img
                key={i}
                src={ThumbDownReaction}
                alt="heart"
                className="h-10 w-10 animate-floating absolute  opacity-0"
              />
            ))}
          </div>
          <div className="relative">
            <button
              className={`h-10 w-10`}
              style={{
                background: `url(${HeartReaction})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
              }}
              onClick={() => setHearts((h) => [...h, { id: Date.now() }])}
            ></button>
            {hearts.map((heart, i) => (
              <img
                key={i}
                src={HeartReaction}
                alt="heart"
                className="h-10 w-10 animate-floating absolute  opacity-0"
              />
            ))}
          </div>
          <div className="relative">
            <button
              className={`h-10 w-10`}
              style={{
                background: `url(${LaughReaction})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
              }}
              onClick={() => setLaugh((h) => [...h, { id: Date.now() }])}
            ></button>
            {laugh.map((heart, i) => (
              <img
                key={i}
                src={LaughReaction}
                alt="sad"
                className="h-10 w-10 animate-floating absolute opacity-0"
              />
            ))}
          </div>
          <div className="relative">
            <button
              className={`h-10 w-10`}
              style={{
                background: `url(${SadReaction})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
              }}
              onClick={() => setSad((h) => [...h, { id: Date.now() }])}
            ></button>
            {sad.map((heart, i) => (
              <img
                key={i}
                src={SadReaction}
                alt="sad"
                className="h-10 w-10 animate-floating absolute  opacity-0"
              />
            ))}
          </div>
        </div>
      )}
      {/* Play Button */}
      {!playing && !showNextChapter && !isFullscreenUnavailable && (
        <button
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-full w-full "
          onClick={handlePlayButtonClicked}
          style={{
            background: `url(${PlayIcon})`,
            backgroundPosition: "center",
            backgroundSize: "50px 50px",
            backgroundRepeat: "no-repeat",
          }}
        ></button>
      )}
    </div>
  );
};

export default VideoPlayer;
