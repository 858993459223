import BottomBar from "assets/images/pages/welcome/bottom-bar.png";
import Step1 from "components/Pages/Welcome/Step1";
import Step2 from "components/Pages/Welcome/Step2";
import Step3 from "components/Pages/Welcome/Step3";
import Step4 from "components/Pages/Welcome/Step4";
import { AuthContext } from "context/Auth";
import useDocumentTitle from "hooks/useDocumentTitle";
import useLayoutConfig from "hooks/useLayoutConfig";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Welcome = () => {
  const navigate = useNavigate();
  const { profile, setProfile } = useContext(AuthContext);

  useLayoutConfig("red", false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [avatar, setAvatar] = useState("");
  const [goal, setGoal] = useState(36000);

  const [step, setStep] = useState(0);
  useDocumentTitle("Muskurahat Foundation | Welcome Steps");

  useEffect(() => {
    if (!profile) return;
    setName(profile.name || profile.googleAuthProvider.name);
    setPhone(profile.phone || "");
    setAvatar(profile.avatar || "");
    setGoal(profile.goal || 36000);
  }, [profile]);

  // use effect to check if profile has been welcomed before or not. if not, navigate to welcome component
  useEffect(() => {
    if (profile && profile.welcomed) {
      // react router navigate
      navigate("/user/dashboard");
    }
  }, [profile]);

  const stepMap = [
    <Step1
      key="0"
      name={name}
      setName={setName}
      profile={profile}
      setProfile={setProfile}
      setStep={setStep}
    />,
    <Step2
      key="1"
      phone={phone}
      setPhone={setPhone}
      profile={profile}
      setProfile={setProfile}
      setStep={setStep}
    />,
    <Step3
      key="2"
      avatar={avatar}
      setAvatar={setAvatar}
      profile={profile}
      setProfile={setProfile}
      setStep={setStep}
    />,
    <Step4
      key="3"
      goal={goal}
      setGoal={setGoal}
      profile={profile}
      setProfile={setProfile}
    />,
  ];

  return (
    <>
      <div className="flex justify-center items-center h-[80vh] flex-col text-center px-2">
        <h1 className="text-white font-bold text-[32px] mb-20">WELCOME!</h1>
        <div>{stepMap[step]}</div>
      </div>

      <img
        src={BottomBar}
        alt="Welcome"
        className="fixed bottom-0 max-w-[600px] w-full"
      />
    </>
  );
};

export default Welcome;
