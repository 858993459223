import { ConfigProvider, Slider } from "antd";
import Counter from "components/Animation/Counter";
import { AuthContext } from "context/Auth";
import { DonationsContext } from "context/Data/Donations";
import { useContext, useEffect, useState } from "react";
import Chart from "react-apexcharts";

const GoalMeter = () => {
  const { profile, setProfile } = useContext(AuthContext);
  const [percentage, setPercentage] = useState(0);
  const [amount, setAmount] = useState(0);
  const donationsContext = useContext(DonationsContext);

  useEffect(() => {
    if (!profile) return;
    let tempPercentage =
      (donationsContext?.donations.reduce(
        (acc, curr) => acc + curr.amount,
        0,
      ) || 0) *
      (100 / profile.goal);
    if (tempPercentage < 5) {
      tempPercentage = 5;
    }
    setAmount(
      donationsContext?.donations.reduce((acc, curr) => acc + curr.amount, 0) ||
        0,
    );
    setPercentage(tempPercentage);
  }, [profile, donationsContext]);

  const [edit, setEdit] = useState(false);

  const marks = {
    18000: "18k",
    36000: "36k",
    54000: "54k",
    72000: "72k",
    90000: "90k",
    108000: "108k",
  };

  return (
    <>
      {edit ? (
        <div className="max-w-[80%] m-auto mt-8 text-center">
          <h2 className="text-sm font-bold text-center">
            SET YOUR GOAL AT <br />{" "}
            <span className="text-primary text-xl">₹ {profile?.goal || 0}</span>
          </h2>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#E22226",
                colorPrimaryBorder: "#E22226",
                colorPrimaryBorderHover: "#E22226",
              },
            }}
          >
            <Slider
              marks={marks}
              step={18000}
              defaultValue={18000}
              min={18000}
              max={108000}
              value={profile?.goal || 0}
              onChange={(v) => {
                setProfile({ ...profile, goal: v });
              }}
            />
          </ConfigProvider>
          <button
            onClick={() => {
              setEdit(false);
            }}
            className="mt-5 bg-primary text-white p-3 rounded-xl shadow-[0px_2px_4px_rgba(0,0,0,0.25)] text-sm"
          >
            CONFIRM
          </button>
        </div>
      ) : (
        <div className="relative max-w-md m-auto">
          <Chart
            options={{
              chart: {
                height: 450,
                type: "radialBar",
                offsetY: -20,
                sparkline: {
                  enabled: true,
                },
              },

              plotOptions: {
                radialBar: {
                  startAngle: -90,
                  endAngle: 90,
                  track: {
                    background: "#564292",
                    strokeWidth: "70%",
                    margin: 5, // margin is in pixels
                    dropShadow: {
                      enabled: false,
                    },
                  },
                  hollow: {
                    size: "50%",
                  },
                  dataLabels: {
                    name: {
                      show: false,
                    },
                    value: {
                      show: false,
                      offsetY: -2,
                      fontSize: "22px",
                    },
                  },
                },
              },
              grid: {
                padding: {
                  top: -10,
                },
              },
              colors: ["#E22226"],
              fill: {
                type: "",
                gradient: {
                  shade: "dark",
                  type: "vertical",
                  opacityFrom: 0.8,
                  opacityTo: 1,
                  stops: [0, 30],
                },
              },
              labels: ["Average Results"],
            }}
            series={[percentage]}
            height={350}
            type="radialBar"

            // type="radialBar"
            // width="100%"
            // height={300}
            // series={[percentage || 0]}
            // options={{
            //   chart: {
            //     height: 550,
            //     type: "radialBar",
            //     offsetY: -10,
            //   },
            //   plotOptions: {
            //     radialBar: {
            //       startAngle: -135,
            //       endAngle: 135,
            //       hollow: {
            //         size: "80%",
            //       },
            //       dataLabels: {
            //         name: {
            //           fontSize: "14px",
            //           fontFamily: "Poppins",
            //           fontWeight: 700,
            //           color: "#000",
            //         },
            //         value: {
            //           show: false,
            //         },
            //       },
            //     },
            //   },
            //   colors: ["#E22226"],
            //   fill: {
            //     width: 1,
            //     type: "gradient",
            //     gradient: {
            //       shade: "dark",
            //       shadeIntensity: 0.15,
            //       inverseColors: false,
            //       opacityFrom: 1,
            //       opacityTo: 1,
            //       stops: [0, 50, 65, 91],
            //     },
            //   },
            //   stroke: {
            //     lineCap: "round",
            //     width: 1,
            //   },
            //   labels: ["AMOUNT RAISED"],
            // }}
          />
          <div className="text-xl text-primary font-bold absolute bottom-12 left-1/2 -translate-x-1/2">
            ₹ <Counter from={0} to={amount} />
          </div>
          <div
            className="absolute bottom-5 left-7 z-10 text-xs text-quaternary"
            style={{ fontSize: "9px" }}
          >
            ₹{" "}
            {donationsContext?.donations.reduce(
              (acc, curr) => acc + curr.amount,
              0,
            ) || 0}
          </div>
          <div
            onClick={() => {
              setEdit(true);
            }}
            className="absolute bottom-5 right-7 z-10 text-xs text-quaternary"
            style={{ fontSize: "9px" }}
          >
            ₹ {profile?.goal}
          </div>
        </div>
      )}
      <div className="text-center">
        {/* <button
          className="shadow-[1px_3px_8px_rgba(0,0,0,0.25)] rounded-[10px] px-3 py-2 bg-white mt-5"
          onClick={() => {
            setEdit(!edit);
          }}
        >
          {edit ? "Confirm" : "Edit"}
        </button> */}
        <p className="mt-4 font-semibold text-[8px] text-center">
          *Will help us fund the education of{" "}
          <span className="text-primary">
            {Math.ceil((profile?.goal || 1) / 18000)}
          </span>{" "}
          {profile?.goal / 18000 === 1 ? "child" : "children"} for an entire
          year.
        </p>
      </div>
    </>
  );
};

export default GoalMeter;
